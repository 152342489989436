import { gql, TypedDocumentNode } from '@apollo/client';
import { CART_FIELDS, CUSTOMER_CART_FIELDS } from './fragments';

export const GET_CART: TypedDocumentNode = gql`
    ${CART_FIELDS}
    query GET_CART(
        $cartId: String!
    ){
        cart(
            cart_id: $cartId
        ) {
            ...CartFields
        }
    }
`;

export const GET_CUSTOMER_CART: TypedDocumentNode = gql`
    ${CART_FIELDS}
    ${CUSTOMER_CART_FIELDS}
    query GET_CART(
        $cartId: String!
    ){
        cart(
            cart_id: $cartId
        ) {
            ...CartFields
            ...CustomerCartFields
        }
    }
`;

export const REMOVE_FROM_CART_MUTATION: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation REMOVE_FROM_CART_MUTATION($input: RemoveItemFromCartInput) {
        removeItemFromCart(input: $input) {
            cart {
                ...CartFields
            }
        }
    }
`;

export const UPDATE_CART_MUTATION: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation UPDATE_CART_MUTATION($input: UpdateCartItemsInput) {
        updateCartItems(input: $input) {
            cart {
                ...CartFields
            }
        }
    }
`;
