import React, { useEffect, useState } from 'react';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { useQuery } from '@apollo/client';
import { GET_PROMOTION_BANNER } from 'graphql/promotions-banner';
import styles from './style.css';

interface TData {
    getPromotionBanner: {
        content: string;
        // eslint-disable-next-line camelcase
        current_time_stamp: string;
        // eslint-disable-next-line camelcase
        end_time_stamp: string;
        // eslint-disable-next-line camelcase
        start_time_stamp: string;
    };
}

const PromotionalContent = (
    {
        content,
        hasCountDown,
        setHasCountdown,
    }: {
        content: React.ReactNode;
        hasCountDown: boolean;
        setHasCountdown: React.Dispatch<React.SetStateAction<boolean>>;
    },
) => {
    const [timeLeft, setTimeLeft] = useState('');
    const storeConfig = useConfig();
    const storeId = storeConfig.store_config.current_store.id;
    const {
        loading, data,
    } = useQuery<TData>(GET_PROMOTION_BANNER, {
        variables: {
            storeId,
        },
    });

    const promoContent = data?.getPromotionBanner?.content;

    useEffect(() => {
        const setupCountdown = (bannerElement: Element) => {
            const startDate = new Date(bannerElement.getAttribute('data-start-date') || '').getTime();
            const endDate = new Date(bannerElement.getAttribute('data-end-date') || '').getTime();
            if (!Number.isNaN(startDate) && !Number.isNaN(endDate)) {
                setHasCountdown(true);

                const updateTime = () => {
                    const now = new Date().getTime();
                    if (now >= startDate && now < endDate) {
                        const distance = endDate - now;
                        const daysInHours = (distance / (1000 * 60 * 60));
                        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        if (daysInHours > 24) {
                            hours = Math.floor(daysInHours);
                        }
                        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                        const paddedHours = `${hours < 10 ? `0${hours}` : hours}`;
                        const paddedMinutes = `${minutes < 10 ? `0${minutes}` : minutes}`;
                        const paddedSeconds = `${seconds < 10 ? `0${seconds}` : seconds}`;
                        setTimeLeft(`${paddedHours} : ${paddedMinutes} : ${paddedSeconds}`);
                    } else {
                        setHasCountdown(false);
                    }
                };

                const intervalId = setInterval(updateTime, 1000);
                return () => clearInterval(intervalId);
            }
            return undefined;
        };

        const timer = setTimeout(() => {
            const bannerElement = document.querySelector('.special-promo-wrap');
            if (bannerElement) {
                return setupCountdown(bannerElement);
            }
            return undefined;
        }, 4000);

        return () => clearTimeout(timer);
    }, [data, content]);

    if (loading || !promoContent) return null;

    return (
        <>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: promoContent }} />
            {hasCountDown && (
                <p id="countdown" className={styles.countdown}>
                    {timeLeft}
                </p>
            )}
        </>
    );
};

export default PromotionalContent;
