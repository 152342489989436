import { gql, TypedDocumentNode } from '@apollo/client';
import { CART_FIELDS } from '../fragments';

export const APPLY_STORE_CREDIT_TO_CART: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation APPLY_STORE_CREDIT_TO_CART(
        $cartId: String!
    ) {
        applyStoreCreditToCart(
            input: {
                cart_id: $cartId,
            }
        ) {
            cart {
                ...CartFields
            }
        }
    }
`;

export const REMOVE_STORE_CREDIT_FROM_CART: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation REMOVE_STORE_CREDIT_FROM_CART(
        $cartId: String!
    ) {
        removeStoreCreditFromCart(
            input:
            { cart_id: $cartId }
        ) {
            cart {
                ...CartFields
            }
        }
    }
`;
