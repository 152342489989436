import React from 'react';
import { useQuery } from '@apollo/client';
import { MainColumn } from 'ui/component/main-column';
import screenSwitch from 'ui/styles/screen-switch.css';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { FETCH_RELATED_PRODUCTS } from 'graphql/related-products/fetchRelatedProducts';
import styles from './style.css';
import { FullProductSlider as ProductSlider } from '../product-slider';

interface RelatedProductItem {
    id: number;
    sku: string;
    name: string;
    'url_key'?: string;
    'type_id': string;
}

interface RelatedProductsByStore {
    'total_count': number;
    items: RelatedProductItem[];
}

interface RelatedProductsData {
    relatedProductsByStore: RelatedProductsByStore;
}

const CrossSellProductsWidgetImpl = () => {
    const config = useConfig();

    const type = 'crosssell';
    const storeId = config?.store_config?.current_store?.id;

    const { error, data, loading } = useQuery<RelatedProductsData>(FETCH_RELATED_PRODUCTS, {
        variables: {
            type,
            storeId,
            location: 'CART',
        },
        skip: !storeId,
    });

    if (loading) {
        return <p>Loading...</p>;
    }
    const products: RelatedProductItem[] = data?.relatedProductsByStore?.items ?? [];
    if (error || products.length === 0 || !config.cross_sell_block_enable) {
        return null;
    }

    return (
        <MainColumn className={styles.crossSellProductsMainColumn}>
            <div className={styles.title}>{config.cross_sell_block_title}</div>
            <div className={screenSwitch.showOnDesktop}>
                <ProductSlider items={products} show={5} location="crossSellProducts" />
            </div>
            <div className={`${screenSwitch.showOnLargeMobile} ${screenSwitch.showOnMediumMobile}`}>
                <ProductSlider items={products} show={3} location="crossSellProducts" />
            </div>
            <div className={screenSwitch.showOnSmallMobile}>
                <ProductSlider items={products} show={2} location="crossSellProducts" />
            </div>
            <div className={screenSwitch.showOnVerySmallMobile}>
                <ProductSlider items={products} show={1} location="crossSellProducts" />
            </div>
        </MainColumn>
    );
};

export const CrossSellProductsWidget = CrossSellProductsWidgetImpl;
