import { ModuleCreator, Duck } from '@silkpwa/redux';
import { GraphQlClient } from 'graphql/base';
import { CREATE_META_EVENT } from 'chefworks-theme/src/graphql/meta/meta-conversion';
import { AppEventBus } from '@silkpwa/module/app-event-bus/app-event-bus';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

export interface IMetaDataLayer {
    push: (eventName: string, eventData: {[key: string]: any}) => void;
}

export const MetaDataLayer = new ModuleCreator<Duck & IMetaDataLayer>({
    inject: ['ecommerceConfig', 'getMetaConfig', 'appEventBus'],
    create(config, getMetaConfig: (config: SilkRestappDataConfigInfoInterface) => {
        enabled: boolean;
        productEnabled: boolean;
        categoryEnabled: boolean;
        cartTrackingEnabled: boolean;
        wishlistTrackingEnabled: boolean;
        searchTrackingEnabled: boolean;
        embroideryTrackingEnabled: boolean;
        initCheckoutTrackingEnabled: boolean;
        paymentMethodTrackingEnabled: boolean;
        portalEnabled: boolean;
    },
    appEventBus: AppEventBus) {
        return new Duck({
            construct() {
                this.events = [];
                this.enabled = false;
            },
            methods: {
                push(eventName, eventData) {
                    if (!this.enabled) {
                        return;
                    }
                    const metaEventInput = {
                        ...eventData,
                        eventName,
                        timestamp: new Date().toISOString(),
                    };
                    if (!this.portalEnabled && metaEventInput.isPortal) {
                        return;
                    }
                    if (metaEventInput.eventName === 'pageview' && !this.productEnabled) {
                        return;
                    }
                    if (metaEventInput.eventName === 'categoryview' && !this.categoryEnabled) {
                        return;
                    }
                    if (metaEventInput.eventName === 'AddToCart' && !this.cartTrackingEnabled) {
                        return;
                    }
                    if (metaEventInput.eventName === 'AddToWishlist' && !this.wishlistTrackingEnabled) {
                        return;
                    }
                    if ((metaEventInput.eventName === 'Search' || metaEventInput.eventName === 'SearchSLI') &&
                        !this.searchTrackingEnabled) {
                        return;
                    }
                    if (metaEventInput.eventName === 'CustomizeProduct' && !this.embroideryTrackingEnabled) {
                        return;
                    }
                    if (metaEventInput.eventName === 'InitiateCheckout' && !this.initCheckoutTrackingEnabled) {
                        return;
                    }

                    GraphQlClient.mutate({
                        mutation: CREATE_META_EVENT,
                        variables: {
                            input: metaEventInput,
                        },
                    });
                    this.events.push(eventName, metaEventInput);
                    if (metaEventInput.evenName === 'SearchSLI') {
                        appEventBus.publish('sli.search.complete');
                    }
                },
            },
            actions: {
                loadConfig(_, getState) {
                    const rawConfig: SilkRestappDataConfigInfoInterface = config.selectors.getRawConfig(getState());
                    const {
                        enabled,
                        productEnabled,
                        categoryEnabled,
                        cartTrackingEnabled,
                        wishlistTrackingEnabled,
                        searchTrackingEnabled,
                        embroideryTrackingEnabled,
                        initCheckoutTrackingEnabled,
                        paymentMethodTrackingEnabled,
                        portalEnabled,
                    } = getMetaConfig(rawConfig);
                    this.enabled = enabled;
                    this.productEnabled = productEnabled;
                    this.categoryEnabled = categoryEnabled;
                    this.cartTrackingEnabled = cartTrackingEnabled;
                    this.wishlistTrackingEnabled = wishlistTrackingEnabled;
                    this.searchTrackingEnabled = searchTrackingEnabled;
                    this.embroideryTrackingEnabled = embroideryTrackingEnabled;
                    this.initCheckoutTrackingEnabled = initCheckoutTrackingEnabled;
                    this.paymentMethodTrackingEnabled = paymentMethodTrackingEnabled;
                    this.portalEnabled = portalEnabled;
                },
            },
            initialize(store) {
                config.afterLoad(() => {
                    store.dispatch(this.actions.loadConfig);
                });
            },
        }) as Duck & IMetaDataLayer;
    },
});
