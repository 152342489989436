import { IDynamicWidget } from 'graphql/cms/dynamic-widgets';

export const getBlockType = (widgetBlock: IDynamicWidget): string => {
    const { title } = widgetBlock;
    const titleParts = title.split(':');
    const type = titleParts[0] ?? '';
    return type.trim().toLowerCase();
};

export const getTitlePart = (title: string, index: number) => {
    const titleParts: string[] = title.split(':');
    const result = titleParts[index] ?? '';
    return result.trim();
};

export const getHeaderTitle = (title: string): string => {
    const titlePart: string = getTitlePart(title, 1);
    const finalTitle = titlePart.length > 0 ? titlePart : title;
    return finalTitle.toUpperCase();
};

export const getTitleText = (title: string): string => {
    const titlePart: string = getTitlePart(title, 0);
    const finalTitleText = titlePart.length > 0 ? titlePart : title;
    return finalTitleText.toUpperCase();
};

export const getTitleTierText = (title: string): string => {
    const titlePart: string = getTitlePart(title, 1);
    return titlePart.length > 0 ? titlePart : '';
};

export const isFreeTier = (tierText: string): boolean => (
    Boolean(tierText && tierText.length > 0 && tierText.toLowerCase().includes('free'))
);

export const matchBlockType = (
    block: IDynamicWidget,
    byType: string,
): boolean => (getBlockType(block) === byType);
