import { gql, TypedDocumentNode } from '@apollo/client';

export const CREATE_META_EVENT: TypedDocumentNode = gql`
    mutation CREATE_META_EVENT($input: MetaEventInput) {
        createMetaEvent(input: $input) {
            success
            message
        }
    }
`;
