import React, { useState, useEffect } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { cartVar, cartIdVar } from 'ui/page/checkout-page/checkout-state';
import { APPLY_STORE_CREDIT_TO_CART, REMOVE_STORE_CREDIT_FROM_CART } from 'graphql/cart/payment-method/store-credit';
import { StepButton } from 'ui/component/checkout/components/buttons/step-button';
import { formatCurrency } from 'ui/component/checkout/util/get-currency';
import { IStoreCreditProps } from './store-credit';
import styles from './style.css';

interface IStoreCreditFormProps extends IStoreCreditProps {
    updateCart: () => Promise<void>;
    isOpen: boolean;
}

export const StoreCreditForm: React.FC<IStoreCreditFormProps> = ({ updateCart, isOpen }) => {
    const t = usePhraseTranslater();
    const cartId = useReactiveVar(cartIdVar);
    const cart = useReactiveVar(cartVar);
    const availableStoreCredit = cart?.applied_store_credit?.current_balance?.value ?? 0;
    const appliedStoreCredit = cart?.applied_store_credit?.applied_balance.value ?? 0;
    const availableCreditCurrency = cart?.applied_store_credit?.current_balance?.currency ?? 'USD';
    const appliedCreditCurrency = cart?.applied_store_credit?.applied_balance?.currency ?? 'USD';
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [applyStoreCredit] = useMutation(APPLY_STORE_CREDIT_TO_CART, {
        variables: {
            cartId,
        },
        onCompleted: async () => {
            await updateCart();
            setLoading(false);
        },
        onError: (error) => {
            setError(error.message);
            setLoading(false);
        },
    });
    const [removeStoreCredit] = useMutation(REMOVE_STORE_CREDIT_FROM_CART, {
        variables: {
            cartId,
        },
        onCompleted: async () => {
            await updateCart();
            setLoading(false);
        },
        onError: (error) => {
            setError(error.message);
            setLoading(false);
        },
    });
    useEffect(() => {
        const fetchData = async () => {
            if (isOpen) {
                setLoading(true);
                await updateCart();
                setLoading(false);
            }
        };
        fetchData();
    }, [isOpen]);
    const handleStoreCredit = async () => {
        setLoading(true);
        await applyStoreCredit();
    };
    const handleRemoveStoreCredit = async () => {
        setLoading(true);
        await removeStoreCredit();
    };
    return (
        <div className={styles.storeCreditWrapper}>
            <div className={styles.storeCreditRow}>
                <label htmlFor="available-store-credit" className={styles.storeCreditLabel}>
                    {t('Available Store Credit')}
                    :
                </label>
                <span
                    className={classes(styles.storeCreditValue, {
                        [styles.highlightedOrange]: appliedStoreCredit > 0,
                    })}
                >
                    {formatCurrency(
                        {
                            currency: availableCreditCurrency,
                            value: (availableStoreCredit - appliedStoreCredit),
                        },
                    )}
                </span>
            </div>
            <div className={styles.storeCreditRow}>
                <label htmlFor="applied-store-credit" className={styles.storeCreditLabel}>
                    {t('Applied Store Credit')}
                    :
                </label>
                <span
                    className={classes(styles.storeCreditValue, {
                        [styles.highlightedGreen]: appliedStoreCredit > 0,
                    })}
                >
                    {formatCurrency({ currency: appliedCreditCurrency, value: appliedStoreCredit })}
                </span>
            </div>
            <div className={styles.storeCreditRow}>
                {appliedStoreCredit === 0 ? (
                    <StepButton
                        onClick={handleStoreCredit}
                        label={t('Apply')}
                        loading={loading}
                    />
                ) : (
                    <StepButton
                        onClick={handleRemoveStoreCredit}
                        label={t('Remove')}
                        loading={loading}
                    />
                )}
                {error && (
                    <div className={styles.error}>{error}</div>
                )}
            </div>
        </div>
    );
};
