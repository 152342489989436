import React from 'react';
import { IDynamicWidget } from 'graphql/cms/dynamic-widgets';
import free from 'assets/images/marketing/free.png';
import paid from 'assets/images/marketing/paid.png';
import { RewardsAccordionItem } from './accordion-item';
import {
    getTitleIconImage,
    getTitleStyles,
} from '../util/html-parser';
import {
    getTitleText,
    getTitleTierText,
    getBlockType,
    isFreeTier,
} from '../util/rewards-processor';
import styles from './style.css';

interface IRewardBlockParams {
    widgetBlock: IDynamicWidget;
    getBlockContent: (block: IDynamicWidget) => JSX.Element;
    accordion: any;
}

export const RewardBlock: React.FC<IRewardBlockParams> = (
    {
        widgetBlock,
        getBlockContent,
        accordion,
    },
) => {
    const {
        title,
        content,
    } = widgetBlock;

    const titleText = getTitleText(title);
    const titleTierText = getTitleTierText(title);
    const type = getBlockType(widgetBlock);
    const iconFromContent = getTitleIconImage(content);
    const imageScr = isFreeTier(titleTierText) ? free : paid;
    const finalIconScr = iconFromContent || imageScr;
    const titleStyles = getTitleStyles(content);

    return (
        <div className={styles.rewardBlockWrapper} data-test={`type-${type}`}>
            <RewardsAccordionItem
                isActive={accordion.isActive(type)}
                toggle={accordion.toggleActive(type)}
                img={finalIconScr}
                type={type}
                text={titleText}
                tierText={titleTierText}
                style={titleStyles}
            >
                <div className={styles.rewardBlockInnerWrapper}>
                    <div className={styles.rewardBlockBody}>{getBlockContent(widgetBlock)}</div>
                </div>
            </RewardsAccordionItem>
        </div>
    );
};
