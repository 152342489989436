import React, { useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import rotateImage from 'assets/images/refreshing.svg';
import closeImage from 'assets/images/close.svg';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { ReviewFormInput } from '../../review-form/form/textfield';
import { ReviewState, VideoUploadResult } from '../../review-form';
import styles from './style.css';

interface VideoPreviewProps {
    image: VideoUploadResult;
    initialReview: ReviewState;
    onVideoUpload: (newVideo: VideoUploadResult[]) => void;
    logo: VideoUploadResult;
    rotation: number;
    index: number;
}

export const VideoPreview: React.FC<VideoPreviewProps> = ({
    image,
    initialReview,
    onVideoUpload,
    logo,
    rotation = 0,
    index,
}) => {
    const config = useConfig();
    const [error, setError] = useState<string | null>(null);
    const validateCaption = (caption: string): boolean => {
        if (caption === '') return true;
        const alphaNumericPattern = /^[a-z0-9! "#$&',.:;@áéíóúñàèìòùâêîôûäëïöüçæœ]+$/i;
        return alphaNumericPattern.test(caption);
    };
    const enableRotate = config.extension_attributes?.cw_reviews_video_rotate_enabled;
    const t = usePhraseTranslater();
    const handleRotationChange = (index) => {
        const newImages = initialReview.uploadedVideoData.map((img, idx) => {
            if (idx === index) {
                const newRotation = (img.rotate || 0) + 90;
                return {
                    ...img,
                    rotate: newRotation % 360,
                };
            }
            return img;
        });
        onVideoUpload(newImages);
    };

    const handleDeleteChange = (index) => {
        const newImages = [...initialReview.uploadedVideoData];
        newImages.splice(index, 1);
        onVideoUpload(newImages);
    };

    const handleCaptionChange = (index, newCaption: string) => {
        if (!validateCaption(newCaption)) {
            setError(t('Invalid input: Please only enter valid characters.'));
            return;
        }
        setError(null);
        const newImages = initialReview.uploadedVideoData.map((img, idx) => (
            idx === index ? {
                ...img,
                caption: newCaption,
            } : img));
        onVideoUpload(newImages);
    };

    return (
        <div className={classes({
                [styles.stylePreview]: true,
            },
            styles.imagePreview)}
        >
            <div className={styles.logoPreview}>
                <img
                    className={classes({
                        [styles.imagePreview]: rotation % 360 === 0,
                        [styles.imagePreview90]: rotation % 360 === 90,
                        [styles.imagePreview180]: rotation % 360 === 180,
                        [styles.imagePreview270]: rotation % 360 === 270,
                    })}
                    src={logo.poster}
                    alt={t(logo.title)}
                />
            </div>
            <div className={styles.inputButtons}>
                <ReviewFormInput
                    name="preview_caption"
                    t={t}
                    inputId="preview_caption"
                    placeholder="Enter caption"
                    value={image.caption || ''}
                    labelTitle="Caption"
                    wrapperId="preview_caption_container"
                    error=""
                    combinedOnChange={e => handleCaptionChange(index, e.target.value)}
                    isBottomSpace={false}
                    isRequired={false}
                />
                {error && <div className={styles.errorMessage}>{error}</div>}
                <div className={styles.actionButtons}>
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={() => handleDeleteChange(index)}
                        onKeyDown={() => handleDeleteChange(index)}
                        className={styles.rotateButton}
                    >
                        <img src={closeImage} alt={t('Remove image')} />
                    </div>
                    {enableRotate && (
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={() => handleRotationChange(index)}
                            onKeyDown={() => handleRotationChange(index)}
                            className={styles.rotateButton}
                        >
                            <img src={rotateImage} alt={t('Rotate image')} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
