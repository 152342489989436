import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { connectRouter } from '@silkpwa/module/react-component/connect-router';
import { connectConfig } from '@silkpwa/module/react-component/connect-config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import { AppEventBus } from '@silkpwa/module/app-event-bus/app-event-bus';
import { useContainer } from '@silkpwa/redux';
import styles from './styles.css';

import {
    cleanupSLI,
    loadSLI,
    getSLIScriptUrl,
    submitSLIForm, restartSLI,
} from './sli-lib';

interface SliSearchProps {
    container: string;
    shouldFocus: boolean;
    ecommerceConfig: any;
    navigate: (location: string) => void;
    toggleSearch?: () => void;
    t: any;
    appEventBus: AppEventBus;
}

class SliSearch extends React.Component<SliSearchProps> {
    private _searchInput;

    private _loaded;

    private _closePopupButton;

    private readonly appEventBus: AppEventBus;

    constructor(props: SliSearchProps) {
        super(props);

        this.appEventBus = props.appEventBus;
        this.formSubmit = this.formSubmit.bind(this);
        this.handleEscape = this.handleEscape.bind(this);
        this.handleUserInout = this.handleUserInout.bind(this);
        this.handleSearchPopupButtonClick = this.handleSearchPopupButtonClick.bind(this);
    }

    componentDidMount() {
        const { shouldFocus } = this.props;
        this.bootstrapScript();
        if (shouldFocus) {
            this._searchInput.focus();
        }
        document.addEventListener('keydown', this.handleEscape, false);
        this._searchInput.addEventListener('keydown', this.handleUserInout, false);
        this._searchInput.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                this.formSubmit(event);
            }
        }, false);
        if (this._closePopupButton) {
            this._closePopupButton.addEventListener('click', this.handleSearchPopupButtonClick, false);
        }
    }

    componentDidUpdate() {
        this.bootstrapScript();
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEscape, false);
        this._searchInput.addEventListener('keydown', this.handleUserInout, false);
        if (this._closePopupButton) {
            this._closePopupButton.addEventListener('click', this.handleSearchPopupButtonClick, false);
        }
        cleanupSLI();
    }

    get isSLIDisabled() {
        const { ecommerceConfig } = this.props;
        return !getSLIScriptUrl(ecommerceConfig);
    }

    async bootstrapScript() {
        if (this.isSLIDisabled) return;
        if (this._loaded) return;

        try {
            const { ecommerceConfig } = this.props;
            await loadSLI(ecommerceConfig);
            this._loaded = true;
        } catch (e) {
            /* ignore error */
        }
    }

    submitNativeSearch(ev) {
        ev.preventDefault();
        const { navigate } = this.props;
        navigate(`/catalogsearch/result?q=${this._searchInput.value}`);
        return false;
    }

    formSubmit(ev) {
        if (this._searchInput.value) {
            if (this.isSLIDisabled) {
                // enable page scroll
                document.body.classList.remove('stop-scrolling');
                document.body.classList.remove('search-open');
                return this.submitNativeSearch(ev);
            }
            if (this.appEventBus) {
                const onSearchComplete = () => {
                    this.appEventBus.unsubscribe('sli.search.complete', onSearchComplete);
                    return submitSLIForm();
                };

                this.appEventBus.subscribe('sli.search.complete', onSearchComplete);
                this.appEventBus.publish('sli.search', { keyword: this._searchInput.value });
                return '';
            }
            return submitSLIForm();
        }
        return '';
    }

    handleEscape(e) {
        if (e.key === 'Escape') {
            const {
                shouldFocus,
                toggleSearch,
            } = this.props;
            if (shouldFocus && toggleSearch) {
                toggleSearch();
            }
            this.handleSearchPopupButtonClick(e);
        }
    }

    handleUserInout() {
        const { container } = this.props;
        // Restarting SLI search as it stop working while using from different location
        restartSLI();
        const searchBarCollection = document.getElementsByClassName('search-bar');
        let searchBar = searchBarCollection[0];
        // closing search input in persistent-menu
        if (container === 'normal-header' && searchBarCollection.length === 2) {
            [, searchBar] = Array.from(searchBarCollection);
        }

        const masthead = document.getElementById('masthead');
        if ((container === 'mobile-header' || container === 'persistent-header' || container === 'normal-header') &&
            this._searchInput.value
        ) {
            if (searchBar && searchBar.classList) {
                searchBar.classList.add('is-active');
            }

            document.body.classList.add('stop-scrolling');
            document.body.classList.add('search-open');

            if (masthead) {
                masthead.style.display = 'block';
            }
        }
    }

    handleSearchPopupButtonClick(e) {
        e.preventDefault();
        const {
            container,
            toggleSearch,
        } = this.props;
        const searchBar = document.getElementsByClassName('search-bar is-active')[0];
        const masthead = document.getElementById('masthead');
        if (container === 'mobile-header' || container === 'persistent-header' || container === 'normal-header') {
            if (searchBar && searchBar.classList) {
                searchBar.classList.remove('is-active');
            }

            document.body.classList.remove('stop-scrolling');
            document.body.classList.remove('search-open');
            this._searchInput.value = '';
            // closing search input in persistent-menu
            if (toggleSearch) {
                toggleSearch();
            }
            if (masthead) {
                masthead.style.display = 'none';
            }
        }
    }

    render() {
        const {
            container,
            t,
        } = this.props;
        const { location } = window;

        const isMobileHeader = container === 'mobile-header' || container === 'hamburger-header';
        const isDesktopHeader = container === 'normal-header' || container === 'persistent-header';
        const containerWrapperClass = container + '-wrapper'; // eslint-disable-line
        const searchForm = (
            <form
                className={classes(
                    'form minisearch sli_searchform',
                    styles.searchForm,
                    styles[container],
                    isDesktopHeader ? styles['desktop-header'] : '',
                )}
                action={`${location.protocol}//${location.host}/search/go`}
                method="GET"
                onSubmit={this.formSubmit}
                acceptCharset="utf-8"
            >
                <div className={styles.searchBox}>
                    <input
                        id="search"
                        type="text"
                        name="w"
                        ref={(e) => {
                            this._searchInput = e;
                        }}
                        placeholder={t('Search by keyword or item #')}
                        className="input-text sli_searchbox_textfield"
                        maxLength={128}
                        role="combobox"
                        aria-haspopup="false"
                        aria-autocomplete="both"
                        autoComplete="off"
                        data-provide="rac"
                        data-sli-append-inputs="true"
                        aria-owns="sli_raclist_suggestions sli_raclist_products sli_raclist_recent"
                        aria-controls="sli_raclist_suggestions sli_raclist_products sli_raclist_recent"
                        aria-expanded="false"
                        required
                    />
                    <input type="hidden" name="ts" value="custom-us" />
                    <button
                        type="submit"
                        className={styles.submitButton}
                    >
                        <i className="fas fa-search" />
                    </button>
                    <button
                        type="button"
                        className={styles.closePopupButton}
                        ref={(e) => {
                            this._closePopupButton = e;
                        }}
                    >
                        <i className="fas fa-times" />
                    </button>
                </div>
                <div id="masthead" className={styles.masthead} />
            </form>
        );

        if (isMobileHeader) {
            return searchForm;
        }

        return (
            <div
                className={classes(
                    styles.desktopHeader,
                    styles[containerWrapperClass],
                )}
            >
                <div className={classes('desktop-header-search search-bar', styles.searchWrapper)}>
                    {searchForm}
                </div>
            </div>
        );
    }
}

const SLIWrap = ({
    navigate,
    stateKey,
    ecommerceConfig,
    container,
    shouldFocus,
    toggleSearch = () => undefined,
}) => {
    const t = usePhraseTranslater();
    const isPunchoutInspectOperation = useIsPunchoutInspectOperation();
    const appEventBus = useContainer<AppEventBus>('appEventBus');

    return isPunchoutInspectOperation ? null : (
        <SliSearch
            key={stateKey}
            navigate={navigate}
            ecommerceConfig={ecommerceConfig}
            container={container}
            shouldFocus={shouldFocus}
            toggleSearch={toggleSearch}
            t={t}
            appEventBus={appEventBus}
        />
    );
};

const ConnectedSLISearch = connectConfig(connectRouter(SLIWrap));

export { ConnectedSLISearch as SLISearch };
