import React, { useEffect, useRef, useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { APPLY_LOYALTY_POINTS_TO_CART, GET_CUSTOMER_LOYALTY_BALANCE } from 'graphql/cart/payment-method/loyalty';
import { IStoreConfig } from 'graphql/config/config';
import { cartIdVar } from 'ui/page/checkout-page/checkout-state';
import { formatCurrency } from 'ui/component/checkout/util/get-currency';
import { EHighlightType, MethodHeader } from '../method-header/method-header';
import styles from './style.css';

interface IRewardsProps {
    updateCart: () => Promise<void>;
    isPortalCustomer: () => boolean;
    config: IStoreConfig | null;
}

export const useCustomerLoyalty = () => {
    const { data, loading, error } = useQuery(
        GET_CUSTOMER_LOYALTY_BALANCE,
        {
            fetchPolicy: 'no-cache',
        },
    );
    return { data, loading, error };
};

export const Rewards: React.FC<IRewardsProps> = ({ isPortalCustomer, updateCart, config }) => {
    const t = usePhraseTranslater();
    const cartId = useReactiveVar(cartIdVar);
    const slider = useRef(null);
    const currency = config?.default_display_currency_code || config?.base_currency_code || 'USD';
    const isLoyaltyProgramEnabled = config?.loyalty_config?.isLoyaltyProgramEnabled || false;

    const { data } = useCustomerLoyalty();
    const [customerLoyaltyData, setCustomerLoyaltyData] = useState({});

    // @ts-ignore
    const loyaltyBalance = customerLoyaltyData?.customerLoyalty?.amount || 0;
    // @ts-ignore
    const maxAmount = customerLoyaltyData?.customerLoyalty?.maxAmount || 0;
    const [sliderProgress, setSliderProgress] = useState({});
    // @ts-ignore
    const [rewardsValue, setRewardsValue] = useState(customerLoyaltyData?.customerLoyalty?.spend || 0);
    const [applyRewardsToCart, { loading, error }] = useMutation(APPLY_LOYALTY_POINTS_TO_CART, {
        variables: {
            cartId,
            points: rewardsValue > 0 ? rewardsValue : 99999, // Send cancel code (99999) if user selects 0
        },
        onCompleted: async () => {
            await updateCart();
        },
    });
    const applyRewards = () => {
        applyRewardsToCart();
    };

    // Animate Progress
    const updateSliderProgress = (value, max) => {
        const bgColor = '#EFEFEF';
        const selectedBgColor = '#44A047';
        const progress = (value / max) * 100;
        if (slider.current !== null) {
            // eslint-disable-next-line
            slider.current.style.background = `linear-gradient(to right, ${selectedBgColor} ${progress}%, ${bgColor} ${progress}%)`;
        }
        const sliderBg = `linear-gradient(to right, ${selectedBgColor} ${progress}%, ${bgColor} ${progress}%)`;
        setSliderProgress({ background: sliderBg });
    };

    const handleInput = (event) => {
        const { value, max } = event.target;
        const roundedMax = Math.floor(parseFloat(max));
        const newValue = Number(value) === Number(roundedMax) ? Number(max) : Number(value); // If we are at the end of the slider, set the value to the max
        setRewardsValue(newValue);
        updateSliderProgress(newValue, max);
    };

    useEffect(() => {
        if (data) {
            setCustomerLoyaltyData(data);
            const value = data?.customerLoyalty?.spend || 0;
            const max = data?.customerLoyalty?.maxAmount || 0;
            setRewardsValue(Number(value));
            updateSliderProgress(value, max);
        }
    }, [data]);

    return (
        <>
            {!isPortalCustomer() && isLoyaltyProgramEnabled && maxAmount > 0 && (
                <MethodHeader
                    methodName="Rewards Cash Balance"
                    highlight={loyaltyBalance && EHighlightType.positive}
                    identifier="rewards"
                >
                    <div className={styles.rewardsWrapper}>
                        <div>
                            <div className={styles.loyaltyBalance}>
                                <span className={styles.currentBalance}>Current Balance </span>
                                {formatCurrency({ currency, value: loyaltyBalance })}
                            </div>
                        </div>
                        {Boolean(loyaltyBalance) && (
                            <div className={styles.sliderWrapper}>
                                <div className={styles.note}>{t('Drag slider to apply rewards cash')}</div>
                                <div className={styles.slider}>
                                    {error && <div className="error">{error.message}</div>}
                                    {loading && <div className="loading">Loading...</div>}
                                    {!loading && (
                                        <div className={styles.loyaltySliderContainer}>
                                            <input
                                                ref={slider}
                                                type="range"
                                                min="0"
                                                max={maxAmount}
                                                value={rewardsValue}
                                                className={styles.loyaltySlider}
                                                id="loyaltySlider"
                                                onInput={handleInput}
                                                onMouseUp={applyRewards}
                                                style={sliderProgress}
                                            />
                                        </div>
                                    )}
                                </div>
                                {maxAmount < loyaltyBalance && (
                                    <div className={classes({
                                        [styles.maxApplicable]: true,
                                        [styles.reached]: rewardsValue === maxAmount,
                                    })}
                                    >
                                        <span className={styles.maxToolTip}>
                                            <span>{t('Reward Cash cannot be used for shipping, tax or some excluded items')}</span>
                                        </span>
                                        <span className={styles.colon}>Max Applicable </span>
                                        {formatCurrency({ currency, value: maxAmount })}
                                    </div>
                                )}
                                <div className={styles.rewardsApplied}>
                                    <span className={styles.colon}>{t('Applied')}</span>
                                    <span>
                                        {formatCurrency({ currency, value: rewardsValue })}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </MethodHeader>
            )}
        </>
    );
};
