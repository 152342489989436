import React from 'react';
import { LogoDataObject } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state/add-embroidery-state';
import { LogoInput } from './logo-input';

interface LogoOnFileProps {
    logo: LogoDataObject;
    logoNum: number;
    embroiderer: EmbroidererProps;
    embroideryPage: any; // TODO: Gave up on this one, will go back to add more correct typing
    allowedLogos: string[];
    accountLogoAllowed: boolean;
}

export const LogoOnFile: React.FC<LogoOnFileProps> = (
    {
        logo,
        logoNum,
        embroiderer,
        embroideryPage,
        allowedLogos,
        accountLogoAllowed,
    },
) => (
    <React.Fragment>
        {logo.logo && logo.logo.type === 'on-file' && (
            <React.Fragment>
                <LogoInput
                    dataTest="logo-onfile"
                    valueObj={logo.logo.valueObj}
                    handleChange={value => (
                        embroideryPage.setLogoOnFile(
                            logoNum,
                            typeof value === 'object' ? value : { value, path: null },
                        )
                    )}
                    handleRemove={() => {
                        embroideryPage.useOnFile(logoNum)();
                    }}
                    isDefaultAndLocked={embroideryPage.isDefaultAndLocked(logo)}
                    embroideryLogoEnabled={embroiderer.embroideryLogoEnabled}
                    liquidPixelEnabled={embroiderer.liquidPixel.enabled}
                    handleLoadLogos={embroiderer.embroideryRepository.loadLogos}
                    allowedLogos={allowedLogos}
                    accountLogoAllowed={accountLogoAllowed}
                />
            </React.Fragment>
        )}
    </React.Fragment>
);
