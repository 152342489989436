import DOMPurify from 'dompurify';
import {
    HTMLElement,
    Node,
    parse,
} from 'node-html-better-parser';
import { IImgAttributes } from '../title-icon/title-icon';

interface IParsedNode extends HTMLElement {
    valid: boolean;
}

interface IStyles {
    [key: string]: string;
}

interface ISanitizedHtml {
    __html: string;
}

export const processHtml = (html: string): ISanitizedHtml => ({ __html: DOMPurify.sanitize(html) });

const findImageNode = (
    childNodes: Node[],
): Node|undefined => childNodes.find((childNode) => {
    const node = childNode as HTMLElement;
    if (!node.tagName) {
        return false;
    }

    return node.tagName === 'img';
});

const getImageFromNode = (imageNode: Node): IImgAttributes => {
    const { attributes } = imageNode as HTMLElement;
    return {
        alt: attributes?.alt || '',
        height: attributes?.height || '',
        src: attributes?.src || '',
        style: attributes?.style || '',
        width: attributes?.width || '',
    };
};

const matchFirstNodeClass = (
    node: IParsedNode,
    className: string,
): boolean => !!(node?.children[0]?.classNames?.includes(className));

const parseHtmlStringToNode = (html: string): IParsedNode|undefined => {
    const blockContentNode: IParsedNode = parse(html);
    if (!blockContentNode.valid) {
        return undefined;
    }

    return blockContentNode;
};

const getFirstMatchedNodeByClassName = (
    html: string,
    className: string,
) => {
    const parsedNode: IParsedNode|undefined = parseHtmlStringToNode(html);
    if (!parsedNode || !matchFirstNodeClass(parsedNode, className)) {
        return undefined;
    }

    return parsedNode;
};

export const getTitleIconImage = (blockContentHtml: string): IImgAttributes|undefined => {
    const blockContentNode: IParsedNode|undefined = getFirstMatchedNodeByClassName(blockContentHtml, 'rewards');
    if (!blockContentNode) {
        return undefined;
    }

    const headerIconDiv = blockContentNode.children[0];
    const imageNode = findImageNode(headerIconDiv.childNodes);
    if (!imageNode) {
        return undefined;
    }

    return getImageFromNode(imageNode);
};

export const getStylesFromString = (stylesString: string, exclude: string[] = []): IStyles => {
    const styleRows = stylesString.split(';');
    const filteredStyleRows = styleRows.filter((styleRow: string) => !!(styleRow.trim()));
    const clearedStyleRows = filteredStyleRows.map((styleRow: string) => (styleRow.trim()));

    const styles: { [key: string]: string } = {};
    clearedStyleRows.forEach((styleRow: string) => {
        const styleProps = styleRow.split(':');
        if (styleProps && styleProps.length === 2 && !exclude.includes(styleProps[0].trim())) {
            const attribute = styleProps[0].trim();
            styles[attribute] = styleProps[1].trim();
        }
    });

    return styles;
};

export const getTitleStyles = (blockContentHtml: string): IStyles => {
    const blockContentNode: IParsedNode|undefined = getFirstMatchedNodeByClassName(blockContentHtml, 'rewards');
    if (!blockContentNode) {
        return {};
    }

    const headerDiv = blockContentNode.children[0];
    const { attributes } = headerDiv as HTMLElement;
    const { style } = attributes;
    if (!style || !style.length) {
        return {};
    }

    return getStylesFromString(style, ['display']);
};
