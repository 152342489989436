import { gql, TypedDocumentNode } from '@apollo/client';

export const ADD_REVIEW_MUTATION: TypedDocumentNode = gql`
    mutation ADD_REVIEW_MUTATION($input: CreateNewReviewInput!) {
        createNewReview(input: $input) {
            success
            message
        }
    }
`;
