import { DuckModuleWithoutReducer, inject } from '@silkpwa/redux';
import { Category as EcommerceCategory } from '@silkpwa/module/ecommerce-catalog/category/category';
import { HasPagination } from '@silkpwa/module/pagination/pagination';
import { AppEventBus } from '@silkpwa/module/app-event-bus/app-event-bus';
import { AccountState } from '@silkpwa/module/account/account-interfaces';

@inject('metaDataLayer', 'router', 'ecommerceProductEntity', 'ecommerceCategory', 'appEventBus',
    'ecommerceWishlist', 'ecommerceConfig', 'account', 'ecommerceSearchPage', 'ecommerceCart')
export class MetaConversionModule extends DuckModuleWithoutReducer {
    private store;

    private readonly pageHandler;

    private hasEmbroidery;

    constructor(
        private metaDataLayer,
        private router,
        private products,
        private category: EcommerceCategory,
        appEventBus: AppEventBus,
        wishlist,
        private config,
        private accountModule,
        private searchPage,
    ) {
        super('MetaConversion');
        this.handlePage = this.handlePage.bind(this);
        this.getUserData = this.getUserData.bind(this);
        this.pageHandler = {
            product: this.handleProductPage.bind(this),
            category: this.handleCategoryPage.bind(this),
            search: this.handleSearch.bind(this),
        };
        this.hasEmbroidery = false;
        appEventBus.subscribe(
            'cart.item.added',
            this.handleCartItemAdded.bind(this),
        );
        wishlist.addEventListener(
            'add',
            this.handleWishlistItemAdded.bind(this),
        );
        appEventBus.subscribe(
            'chefworks.embroidery.saved',
            this.handleEmbroiderySaved.bind(this),
        );

        appEventBus.subscribe(
            'checkout.step.start',
            this.handleCheckoutInitiated.bind(this),
        );

        appEventBus.subscribe(
            'sli.search',
            this.handleSLISearch.bind(this),
        );
    }

    initialize(store) {
        this.store = store;
        this.router.onPageLoaded(this.handlePage);
    }

    /**
     * Dispatches to the correct page handler.
     */
    handlePage() {
        const getResource = this.router.selectors.getCurrentResourceInfo;
        const {
            resourceType,
            resourceId,
        } = getResource(this.store.getState());
        if (typeof this.pageHandler[resourceType] === 'function') {
            this.pageHandler[resourceType](resourceId);
        }
    }

    /**
     * Handles the product page.
     */
    handleProductPage(resourceId) {
        const location = this.router.selectors.location(this.store.getState());
        const state = this.store.getState();
        const ecommerceConfig = this.config
            .selectors
            .getRawConfig(state);
        const { currency } = ecommerceConfig.extension_attributes;
        const productId = resourceId;
        const product = this.products.selectors.getProduct(state, productId);
        const pageData = {
            metaEventName: 'ViewContent',
            contentName: product.name,
            contentIds: String(resourceId),
            contentType: 'product',
            sku: product.sku,
            url: location.pathname,
            currency,
            value: MetaConversionModule.getValue(product.price),
            userData: this.getUserData(),
            isPortal: this.getIsPortal() ?? false,
        };
        this.metaDataLayer.push('pageview', pageData);
    }

    /**
     * Handles the category page.
     */
    handleCategoryPage(resourceId) {
        const location = this.router.selectors.location(this.store.getState());
        const category = this.getCategory();
        const items = this.getItems();
        const contentIds = JSON.stringify(items.map(item => String(item.id)));
        const pageData = {
            metaEventName: 'ViewCategory',
            contentName: category.name,
            categoryId: resourceId,
            contentIds,
            contentType: 'category',
            url: location.pathname,
            userData: this.getUserData(),
            isPortal: this.getIsPortal() ?? false,
        };
        this.metaDataLayer.push('categoryview', pageData);
    }

    static getValue(price: string) {
        const numericValue = price.match(/[\d.,]+/g)?.[0];
        if (!numericValue) {
            throw new Error(`Invalid price format: ${price}`);
        }

        const value = parseFloat(numericValue.replace(',', ''));
        return value;
    }

    createEventData(eventName, product, simpleProduct, qty) {
        const state = this.store.getState();
        const ecommerceConfig = this.config.selectors.getRawConfig(state);
        const { currency } = ecommerceConfig.extension_attributes;
        if (!simpleProduct) {
            return {
                metaEventName: eventName,
                contentName: product.name,
                contentIds: String(product.id),
                contentType: 'product',
                sku: product.sku,
                currency,
                value: MetaConversionModule.getValue(product.price),
                quantity: 1,
                userData: this.getUserData(),
                hasEmbroidery: this.hasEmbroidery ?? false,
                isPortal: this.getIsPortal() ?? false,
            };
        }
        return {
            metaEventName: eventName,
            contentName: product.name,
            contentIds: String(simpleProduct.id),
            contentType: 'product',
            sku: simpleProduct.sku,
            currency,
            value: MetaConversionModule.getValue(simpleProduct.price),
            quantity: qty,
            userData: this.getUserData(),
            isPortal: this.getIsPortal() ?? false,
            hasEmbroidery: this.hasEmbroidery ?? false,
        };
    }

    handleCartItemAdded(product, simpleProduct, qty) {
        const pageData = this.createEventData('AddToCart', product, simpleProduct, qty);
        this.metaDataLayer.push('AddToCart', pageData);
    }

    handleEmbroiderySaved(product, simpleProduct, qty) {
        this.hasEmbroidery = true;
        const pageData = this.createEventData('CustomizeProduct', product, simpleProduct, qty);
        this.metaDataLayer.push('CustomizeProduct', pageData);
    }


    handleWishlistItemAdded(product, simpleProduct, qty) {
        const state = this.store.getState();
        const productSend = this.products.selectors.getProduct(state, product);
        const pageData = this.createEventData('AddToWishlist', productSend, simpleProduct, qty);
        this.metaDataLayer.push('AddToWishlist', pageData);
    }

    createCheckoutData(eventName, data) {
        const state = this.store.getState();
        const ecommerceConfig = this.config.selectors.getRawConfig(state);
        const { currency } = ecommerceConfig.extension_attributes;
        const contentIds = JSON.stringify(
            data.items
                .filter(item => item.configured_variant)
                .map(item => String(item.configured_variant.sku)),
        );
        return {
            metaEventName: eventName,
            contentIds,
            contentType: 'product',
            currency,
            contents: JSON.stringify(MetaConversionModule.getCheckoutContents(data.items)),
            value: data.prices?.grand_total?.value ?? 0.00,
            quantity: data.total_quantity,
            userData: this.getUserData(),
            isPortal: this.getIsPortal() ?? false,
        };
    }

    static getCheckoutContents(items) {
        return items
            // eslint-disable-next-line no-underscore-dangle
            .filter(item => item.__typename !== 'VirtualCartItem')
            .map((item) => {
                const price = parseFloat(item.prices?.row_total?.value) ?? 0.00;
                return {
                    id: String(item?.id) ?? 0,
                    price,
                    quantity: item?.quantity ?? 0,
                };
            });
    }

    handleCheckoutInitiated(data) {
        const pageData = this.createCheckoutData('InitiateCheckout', data);
        this.metaDataLayer.push('InitiateCheckout', pageData);
    }

    getCategory() {
        const { getCurrentCategory } = this.category.selectors;
        const { getState } = this.store;
        const category = getCurrentCategory(getState());
        return category;
    }

    getItems() {
        const { pagination } = this.category.ducks as unknown as HasPagination;
        const { getItems } = pagination.selectors;
        const { getState } = this.store;
        const { items } = getItems(getState());
        return items ?? [];
    }

    getUserData() {
        const state = this.store.getState();
        const account = this.accountModule.selectors.getAccount(state);
        if (!account.isLoggedIn) {
            return null;
        }
        return {
            em: account.info.email,
            external_id: account.info.id,
            fn: account.info.firstName,
            ln: account.info.lastName,
        };
    }

    handleSearch() {
        const state = this.store.getState();
        const searchString = this.searchPage.selectors.getSearchTerm(state);
        const pageData = {
            metaEventName: 'Search',
            searchString,
            userData: this.getUserData(),
            isPortal: this.getIsPortal() ?? false,
        };
        this.metaDataLayer.push('Search', pageData);
    }

    handleSLISearch(data) {
        const searchString = data.keyword;
        if (!searchString || searchString.trim() === '') {
            return;
        }
        const pageData = {
            metaEventName: 'Search',
            searchString,
            userData: this.getUserData(),
            isPortal: this.getIsPortal() ?? false,
        };
        this.metaDataLayer.push('SearchSLI', pageData);
    }

    getIsPortal(): boolean {
        const state = this.store.getState();
        const account = this.accountModule.selectors.getAccount(state);
        if (!account.isLoggedIn) {
            return false;
        }
        const portalInfo = MetaConversionModule.getPortalInfo(account);
        return portalInfo;
    }

    static getPortalInfo(account: AccountState): boolean {
        if (account.isLoggedIn) {
            const isPortal = account.info.extensionAttributes?.portal_data?.entity_id;
            if (isPortal !== 0) {
                return true;
            }
            return false;
        }
        return false;
    }
}
