// Validation logic for email format
import { FocusEvent } from 'react';
import DOMPurify from 'dompurify';
import { HasCustomerId, Vote, VotesAggregationResult } from './type-helper';

export const isEmailValid = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
};

export const calculateDiscountPercentage = (
    listPrice: number,
    sellingPrice: number,
    decimalPlaces = 2,
): string|number => {
    if (listPrice === 0) return 0; // Avoid division by zero
    const discount = ((sellingPrice - listPrice) / listPrice) * 100;
    return `${parseFloat(discount.toFixed(decimalPlaces))}%`;
};

export const sanitizeInput = (value: string) => DOMPurify.sanitize(value);

export const validationHandler = (
    event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>,
    setErrorData: (name: string, message: string) => void,
) => {
    const {
        name,
        value,
        required,
        type,
        maxLength,
    } = event.target;
    if (required && value.trim() === '') {
        setErrorData(name, 'Required');
    } else if (type === 'email') {
        const isValid = isEmailValid(value);
        if (!isValid) {
            setErrorData(name, 'Please enter a valid email');
        } else {
            setErrorData(name, '');
        }
    } else if (type === 'number') {
        if (event.target instanceof HTMLInputElement) {
            const { min, max } = event.target;
            const minValue = min ? parseInt(min, 10) : undefined;
            const maxValue = max ? parseInt(max, 10) : undefined;

            if (minValue && value.length < minValue) {
                setErrorData(name, `The input length is less than the minimum allowed: ${minValue}`);
            }

            if (maxValue && value.length > maxValue) {
                setErrorData(name, `The input length is less than the minimum allowed: ${maxValue}`);
            }
        }
    } else {
        if (maxLength && value.trim().length > maxLength) {
            setErrorData(name, `You have exceed the maximum length( ${maxLength} )`);
        }
        setErrorData(name, '');
    }
};

export const generateUniqueKey = (index: number): string => `${Date.now()}-${Math.random()}-${index}`;

export const getSortOptions = () => [
    {
        id: 'date-desc',
        label: 'Most Recent',
    },
    {
        id: 'rating-asc',
        label: 'Lowest Rated',
    },
    {
        id: 'rating-desc',
        label: 'Highest Rated',
    },
    {
        id: 'date-asc',
        label: 'Oldest',
    },
];

export function timeAgo(timestamp: string) {
    const now = new Date();
    const pastDate = new Date(timestamp);
    // @ts-ignore
    const secondsAgo = Math.floor((now - pastDate) / 1000);

    const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const [unit, seconds] of Object.entries(intervals)) {
        const elapsed = Math.floor(secondsAgo / seconds);
        if (elapsed >= 1) {
            return `${elapsed} ${unit}${elapsed > 1 ? 's' : ''} ago`;
        }
    }
    return 'Just now';
}

// INFO: Function to aggregate thumbs-up (1) and thumbs-down (-1) votes
export const aggregateVotes = (votes: Vote[]): VotesAggregationResult => {
    if (votes && votes.length) {
        return votes.reduce(
            (acc, currentVote) => {
                if (currentVote.vote === 1) {
                    acc.thumbsUp += 1;
                } else if (currentVote.vote === -1) {
                    acc.thumbsDown += 1;
                }
                return acc;
            },
            { thumbsUp: 0, thumbsDown: 0 },
        );
    }
    return { thumbsUp: 0, thumbsDown: 0 };
};

// INFO: Generic function to filter by customer_id
export const filterByCustomerId = <T extends HasCustomerId>(
    data: T[],
    customerId: number,
): (T & { thumbsUp: boolean; thumbsDown: boolean })[] => data
    .filter(item => item.customer_id === customerId)
    .map(item => ({
        ...item,
        thumbsUp: item.vote === 1,
        thumbsDown: item.vote === -1,
    }));
