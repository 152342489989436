import React from 'react';
import { usePriceFormatter, usePriceRounder } from '@silkpwa/module/ecommerce-catalog/config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useAccount } from '@silkpwa/module/account';
import { classes } from '@silkpwa/module/util/classes';
import styles from './styles.css';

interface IOriginalPriceProps {
    product: any; // TODO: update type
    calculatedOriginalPrice?: number | undefined;
    isMsrpShowEnabled?: boolean;
    isListingPage?: boolean;
}

export const OriginalPrice: React.FC<IOriginalPriceProps> = ({
    product,
    calculatedOriginalPrice,
    isMsrpShowEnabled,
    isListingPage,
}) => {
    const t = usePhraseTranslater();
    const account = useAccount();
    const roundPrice = usePriceRounder();
    const formatPrice = usePriceFormatter();
    const originalPrice = roundPrice(calculatedOriginalPrice || product.unformatted.originalPrice);
    const price = roundPrice(product.unformatted.price);
    const msrpPrice = roundPrice(product.unformatted.msrpPrice);
    if (!price || originalPrice <= price) {
        if (isMsrpShowEnabled && msrpPrice && !(msrpPrice <= price)) {
            return (
                <div
                    data-test="original-price"
                    data-original-price={originalPrice}
                    data-price={price}
                    data-msrp-price={msrpPrice}
                    className={classes(
                        styles.originalPriceNotStrike,
                        {
                            [styles.listingPgStyle]: isListingPage,
                            [styles.originalPriceUnsetMargin]: isListingPage,
                        },
                    )}
                >
                    {isListingPage ? (
                        <span>
                            {t('List:')}
                        </span>
                    ) : (
                        <span>
                            {t('List Price:')}
                        </span>
                    )}
                    <span className={styles.originalPriceSpan}>{formatPrice(msrpPrice)}</span>
                </div>
            );
        }
        return null;
    }
    const { info } = account;
    const {
        extensionAttributes: ac,
    } = info;
    const portalData = ac?.portal_data;
    const hideStrikethroughPrice =
        Boolean(portalData?.is_active && portalData.code && portalData.hide_strikethrough_price);
    if (hideStrikethroughPrice) {
        return null;
    }
    return (
        <>
            {isMsrpShowEnabled ? (
                <div
                    data-test="original-price"
                    data-original-price={originalPrice}
                    data-price={price}
                    className={classes(styles.originalPriceNotStrike, {
                        [styles.originalPriceUnsetMargin]: isListingPage,
                    })}
                >
                    {isListingPage ? (
                        <span>
                            {t('List:')}
                        </span>
                    ) : (
                        <span>
                            {t('List Price:')}
                        </span>
                    )}
                    <span className={styles.originalPriceSpan}>
                        {formatPrice(originalPrice)}
                    </span>
                </div>
            ) : (
                <div
                    data-test="original-price"
                    data-original-price={originalPrice}
                    data-price={price}
                    className={styles.originalPrice}
                >
                    {formatPrice(originalPrice)}
                </div>
            )}
        </>
    );
};
