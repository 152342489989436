import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccordionGroupState } from '@silkpwa/module/react-component/accordion-group-state';
import { GET_DYNAMIC_WIDGETS, IDynamicWidget } from 'graphql/cms/dynamic-widgets';
import rewardsSmall from 'assets/images/marketing/rewards_small.png';
import { RewardBlock } from './reward-block/reward-block';
import { TitleIcon } from './title-icon/title-icon';
import { processHtml, getTitleIconImage } from './util/html-parser';
import { getHeaderTitle, matchBlockType } from './util/rewards-processor';
import styles from './style.css';

export const RewardsContent: React.FC = () => {
    const [rewardsCashWidgetHeader, setRewardsCashWidgetHeader] = useState<IDynamicWidget|undefined>(undefined);
    const [rewardsCashWidgetBlocks, setRewardsCashWidgetBlocks] = useState<IDynamicWidget[]|null>(null);
    const [rewardsCashWidgetFooter, setRewardsCashWidgetFooter] = useState<IDynamicWidget|undefined>(undefined);

    const t = usePhraseTranslater();
    const storeConfig = useConfig();

    const [getRewardsCashWidget] = useLazyQuery(GET_DYNAMIC_WIDGETS, {
        variables: {
            location: 'EARNREWARDSCASH',
            storeId: storeConfig?.store_config?.current_store?.id,
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: () => {
            setRewardsCashWidgetHeader(undefined);
            setRewardsCashWidgetBlocks(null);
            setRewardsCashWidgetFooter(undefined);
        },
        onCompleted: (data): void => {
            const { getDynamicWidgets } = data;
            const foundHeaderBlock: IDynamicWidget|undefined = getDynamicWidgets.find(
                (widgetBlock: IDynamicWidget) => (matchBlockType(widgetBlock, 'header')),
            );
            const foundContentBlocks: IDynamicWidget[] = getDynamicWidgets.filter(
                (widgetBlock: IDynamicWidget) => (
                    !matchBlockType(widgetBlock, 'header') && !matchBlockType(widgetBlock, 'footer')
                ),
            );
            const foundFooterBlock: IDynamicWidget|undefined = getDynamicWidgets.find(
                (widgetBlock: IDynamicWidget) => (matchBlockType(widgetBlock, 'footer')),
            );
            setRewardsCashWidgetHeader(foundHeaderBlock);
            setRewardsCashWidgetBlocks(foundContentBlocks);
            setRewardsCashWidgetFooter(foundFooterBlock);
        },
    });

    const loadWidgetData = async () => {
        await getRewardsCashWidget();
    };

    const getBlockContent = (widgetBlock: IDynamicWidget): JSX.Element => {
        const content = processHtml(widgetBlock.content);
        // eslint-disable-next-line react/no-danger
        return (<div dangerouslySetInnerHTML={content} className={styles.widgetBlock} />);
    };

    const rewardsCashWidgetHeaderTitle = rewardsCashWidgetHeader && rewardsCashWidgetHeader.title
        ? getHeaderTitle(rewardsCashWidgetHeader.title)
        : t('EARN REWARDS CASH');

    const iconFromHeaderContent = rewardsCashWidgetHeader && rewardsCashWidgetHeader.content
        ? getTitleIconImage(rewardsCashWidgetHeader.content)
        : undefined;
    const finalHeaderIcon = iconFromHeaderContent || rewardsSmall;

    useEffect(() => {
        loadWidgetData();
    }, []);

    if (!rewardsCashWidgetBlocks || !rewardsCashWidgetBlocks.length) {
        return null;
    }

    return (
        <div className={styles.main}>
            <div className={styles.popout}>
                <div className={styles.popoutBody}>
                    <div className={styles.title}>
                        <TitleIcon
                            img={finalHeaderIcon}
                        />
                        {rewardsCashWidgetHeaderTitle}
                    </div>
                    {rewardsCashWidgetHeader && rewardsCashWidgetHeader.content && (
                        <div className={styles.header}>{getBlockContent(rewardsCashWidgetHeader)}</div>
                    )}
                    <AccordionGroupState>
                        {(accordion: any) => (
                            <ul className={styles.rewardsAccordionItem}>
                                {rewardsCashWidgetBlocks && rewardsCashWidgetBlocks.map(
                                    (widgetBlock: IDynamicWidget) => (
                                        <RewardBlock
                                            widgetBlock={widgetBlock}
                                            getBlockContent={getBlockContent}
                                            accordion={accordion}
                                        />
                                    ),
                                )}
                            </ul>
                        )}
                    </AccordionGroupState>
                </div>
            </div>
            {rewardsCashWidgetFooter && rewardsCashWidgetFooter.content && (
                <div className={styles.popoutFooter}>{getBlockContent(rewardsCashWidgetFooter)}</div>
            )}
        </div>
    );
};
