import React from 'react';
import { ProductImage } from 'ui/component/product/product-image';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { HoverIcons } from '../hover-icons';
import { HoverRelatedIcons } from '../hover-icons/related-hover-icons';
import styles from './style.css';

export const Images = ({
    images,
    product,
    openQuickView,
    location,
}) => {
    const t = usePhraseTranslater();
    return (
        <div data-test="product-item" data-sku={product.sku} className={styles.imageWrap}>
            <ProductImage product={{ ...product, images }} />
            {(location === 'relatedProducts' ||
                location === 'upsellProducts' ||
                location === 'crossSellProducts'
            ) ? (
                <HoverRelatedIcons
                    openQuickView={openQuickView}
                    className={styles.iconsRelatedWrap}
                    buttonText={t('Quick Buy')}
                    isButtonOnTop
                />
            ) : (
                <HoverIcons
                    product={product}
                    openQuickView={openQuickView}
                    className={styles.iconsWrap}
                />
            )}
        </div>
    );
};
