import { Notifications } from 'module/notifications';
import { loadEmbroideryRepository } from 'ui/component/embroidery-configurator/api';
import { loadChefworksDataLayer } from 'module/ga3-data-layer';
import { loadChefworksGa4DataLayer } from 'module/ga4-data-layer';
import { loadDataLayer } from '@silkpwa/module/gtm-data-layer';
import { loadGa4DataLayer } from '@silkpwa/module/ga4-gtm-data-layer';
import { loadGoogleRecaptcha } from '@silkpwa/module/google-recaptcha';
import { loadCertona } from '@silkpwa/module/certona';
import { loadChefworksCertona } from 'module/certona';
import { loadMetaDataLayer } from '@silkpwa/module/meta-convserion-data-layer';
import { Container } from '@silkpwa/redux';
import { loadMetaModule } from '../module/meta';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

const loadCMSBindings = (container: Container) => {
    container.bind('globalCMSContainers', {
        'social-media': true,
        'email-connect': true,
        'footer-logo': true,
        'footer-contact-info': true,
        'footer-left-links': true,
        'footer-right-links': true,
        'footer-icons': true,
        'footer-buttons': true,
        'footer-secure-image': true,
        'footer-bottom-links': true,
        'footer-copyright': true,
        'recently-viewed-footer': true,
        'menu-promotion-1': true,
        'menu-promotion-2': true,
        'menu-promotion-3': true,
        'menu-promotion-4': true,
        'menu-promotion-5': true,
        'menu-promotion-6': true,
        'menu-promotion-7': true,
        'menu-promotion-8': true,
        'menu-promotion-9': true,
        'menu-promotion-10': true,
        'banner-promotion': true,
    });

    const cmsRoutingPageTypes = {
        homepage: true,
        cms: true,
        checkout: true,
        success: true,
    };

    container.bind(
        'cmsSetRouteProgress',
        (type: keyof typeof cmsRoutingPageTypes) => cmsRoutingPageTypes[type],
    );
};

export type GtmConfigSelector = (config: SilkRestappDataConfigInfoInterface) => string | undefined;
export type Ga4GtmConfigSelector = (config: SilkRestappDataConfigInfoInterface) => string | undefined;
export type CwHomepageEnabledReviewConfigSelector = (config: SilkRestappDataConfigInfoInterface) => string | undefined;

export function loadChefworksModules(container: Container) {
    loadCMSBindings(container);

    container.bind('chefworksNotifications', Notifications);
    container.load(loadEmbroideryRepository);
    container.load(loadChefworksDataLayer);
    container.load(loadChefworksGa4DataLayer);
    container.load(loadGoogleRecaptcha);
    container.load(loadDataLayer);
    container.load(loadGa4DataLayer);
    container.bind(
        'gtmConfigSelector',
        (config: SilkRestappDataConfigInfoInterface) => config.extension_attributes?.gtm_id,
    );
    container.bind(
        'ga4GtmConfigSelector',
        (config: SilkRestappDataConfigInfoInterface) => config.extension_attributes?.ga4_gtm_id,
    );
    container.bind(
        'cwHomepageEnabledReviewConfigSelector',
        (config: SilkRestappDataConfigInfoInterface) => config.extension_attributes?.cw_reviews_enabled,
    );

    // Only show toasts for errors from M2 API.
    // Any other messages it wants to show must be ignored.
    container.bind(
        'messageRepositoryFilter',
        message => message.type === 'magento-error',
    );

    container.bind('googleRecaptchaUrls', {
        '/app-login': 'login',
        '/app-subscribe-email': 'newsletter',
    });

    container.load(loadCertona);
    container.load(loadChefworksCertona);
    container.load(loadMetaDataLayer);
    container.load(loadMetaModule);
}
