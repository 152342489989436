import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';

import IPRuleLogoEmb = Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleLogoEmbInterface;

export const findLogoRulePerLine = (lineNumber: number, embroiderer: EmbroidererProps): IPRuleLogoEmb|undefined => {
    const { allowedLogoNumbers } = embroiderer;
    if (!allowedLogoNumbers || !allowedLogoNumbers.length) {
        return undefined;
    }

    return allowedLogoNumbers.find(
        (allowedLogoNumber: IPRuleLogoEmb) => {
            const { line_number: ruleLineNumber } = allowedLogoNumber;
            return ruleLineNumber === lineNumber;
        },
    );
};
