import React from 'react';
import { Process } from 'ui/component/checkout/components/process';
import styles from './style.css';

interface IStepButtonProps {
    onClick: () => void;
    label: string;
    loading: boolean;
}

export const StepButton: React.FC<IStepButtonProps> = ({ onClick, label, loading }) => (
    <button
        className={styles.stepButton}
        type="button"
        onClick={onClick}
        disabled={loading}
    >
        <span>
            {label}
            {loading && '\u00A0'}
        </span>
        <Process loading={loading} />
    </button>
);
