import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { customerVar } from 'ui/page/checkout-page/checkout-state';
import { MethodHeader } from '../method-header/method-header';
import { StoreCreditForm } from './store-credit-form';

export interface IStoreCreditProps {
    updateCart: () => Promise<void>;
}

export const StoreCredit: React.FC<IStoreCreditProps> = ({ updateCart }) => {
    const customer = useReactiveVar(customerVar);
    const isCustomerLoggedIn = !!customer;
    const enabledStoreCredit = customer?.store_credit?.enabled ?? false;
    const availableStoreCredit = customer?.store_credit?.current_balance?.value ?? 0;
    const [isOpen, setIsOpen] = useState(false);
    return (
        isCustomerLoggedIn && enabledStoreCredit && availableStoreCredit > 0 ? (
            <MethodHeader
                methodName="Store Credit"
                open={isOpen}
                identifier="store-credit"
                highlight={availableStoreCredit.positive}
                onToggle={() => setIsOpen(!isOpen)}
            >
                <StoreCreditForm updateCart={updateCart} isOpen={isOpen} />
            </MethodHeader>
        ) : null
    );
};
