import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { ISUCustomer } from '@silkpwa/magento/api/super-user-repository/repository';
import styles from './styles.css';

interface ISuperUserCustomerLineProps {
    suCustomer: ISUCustomer;
    index: number;
    type: string;
    customerText?: string;
    handleClick?: (selectedSuInfo?: ISUCustomer|undefined) => void;
}

export const SU_LINE_TYPE_MAIN_BANNER = 'su-main-banner-line';
export const SU_LINE_TYPE_AUTOCOMPLETE = 'su-autocomplete-line';
export const SU_LINE_TYPE_ASSIGNED_AND_ACTIVE = 'su-assigned-and-active-line';
export const SU_LINE_TYPE_SELECTED_INFO = 'su-selected-info';

export const SuperUserCustomerLine: React.FC<ISuperUserCustomerLineProps> = (
    {
        suCustomer,
        index,
        type,
        customerText,
        handleClick,
    },
) => {
    const t = usePhraseTranslater();
    const isMainBannerLineType = type === SU_LINE_TYPE_MAIN_BANNER;
    const isAutocompleteLineType = type === SU_LINE_TYPE_AUTOCOMPLETE;
    const isAssignedAndActiveLineType = type === SU_LINE_TYPE_ASSIGNED_AND_ACTIVE;
    const isSelectedInfoType = type === SU_LINE_TYPE_SELECTED_INFO;
    const {
        id: suId,
        email: suEmail,
        name: suName,
        customer_number: suCustomerNumber,
        company_name: suCompanyName,
    } = suCustomer;
    const processClick = (
        event: React.MouseEvent<HTMLDivElement>|React.KeyboardEvent<HTMLDivElement>,
        info: ISUCustomer,
    ): void => {
        event.preventDefault();
        const { id, email } = info;
        if (handleClick && id && email) {
            handleClick(info);
        }
    };

    const emailParts = suEmail.split('#');
    const propertyCode = emailParts.length === 2 ? emailParts[0] : undefined;
    const viewEmail = emailParts.length === 2 ? emailParts[1] : suEmail;

    return (
        <div
            role="button"
            tabIndex={index}
            className={classes(styles.suCustomerInfoLine, {
                [styles.clickable]: !!handleClick,
                [styles.suMainLine]: isMainBannerLineType,
                [styles.autocompleteLine]: isAutocompleteLineType,
                [styles.assignedActiveLine]: isAssignedAndActiveLineType,
                [styles.selectedSuInfoLine]: isSelectedInfoType,
            })}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => processClick(event, suCustomer)}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => processClick(event, suCustomer)}
        >
            {!isMainBannerLineType && suId && customerText && (
                <div className={classes(styles.suCustomerLineWrap, styles.customerId)}>
                    <span className={styles.suCustomerLine}>{t(customerText, suId)}</span>
                </div>
            )}
            {!isAssignedAndActiveLineType && suName && (
                <div className={classes(styles.suCustomerLineWrap, styles.customerName)}>
                    {isSelectedInfoType && (
                        <span className={styles.suCustomerLineBefore}>{t('Name: ')}</span>
                    )}
                    {!isSelectedInfoType && !isMainBannerLineType && (
                        <span className={styles.suCustomerLineBefore}>-</span>
                    )}
                    {!isMainBannerLineType && (
                        <span className={styles.suCustomerLine}>{suName.trim()}</span>
                    )}
                    {isMainBannerLineType && customerText && (
                        <span className={styles.suCustomerLine}>{t(customerText, suName.trim())}</span>
                    )}
                </div>
            )}
            {!isAssignedAndActiveLineType && suCustomerNumber && (
                <div className={classes(styles.suCustomerLineWrap, styles.customerName)}>
                    {isSelectedInfoType && (
                        <span className={styles.suCustomerLineBefore}>{t('Customer Number: ')}</span>
                    )}
                    {!isSelectedInfoType && !isMainBannerLineType && (
                        <span className={styles.suCustomerLineBefore}>-</span>
                    )}
                    {!isMainBannerLineType && (
                        <span className={styles.suCustomerLine}>{suCustomerNumber}</span>
                    )}
                    {isMainBannerLineType && customerText && (
                        <span className={styles.suCustomerLine}>{t(customerText, suCustomerNumber)}</span>
                    )}
                </div>
            )}
            {!isAssignedAndActiveLineType && suCompanyName && (
                <div className={classes(styles.suCustomerLineWrap, styles.customerName)}>
                    {isSelectedInfoType && (
                        <span className={styles.suCustomerLineBefore}>{t('Company Name: ')}</span>
                    )}
                    {!isSelectedInfoType && !isMainBannerLineType && (
                        <span className={styles.suCustomerLineBefore}>-</span>
                    )}
                    {!isMainBannerLineType && (
                        <span className={styles.suCustomerLine}>{suCompanyName}</span>
                    )}
                    {isMainBannerLineType && customerText && (
                        <span className={styles.suCustomerLine}>{t(customerText, suCompanyName)}</span>
                    )}
                </div>
            )}
            {viewEmail && (
                <div className={classes(styles.suCustomerLineWrap, styles.customerEmail)}>
                    {isSelectedInfoType && (
                        <span className={styles.suCustomerLineBefore}>{t('Email: ')}</span>
                    )}
                    {!isSelectedInfoType && (
                        <span className={styles.suCustomerLineBefore}>-</span>
                    )}
                    <span className={styles.suCustomerLine}>
                        {(!isAssignedAndActiveLineType ? viewEmail : `${viewEmail} (${t('Logged In Now')})`)}
                    </span>
                </div>
            )}
            {propertyCode && (
                <div className={classes(styles.suCustomerLineWrap, styles.customerPropertyCode)}>
                    {isSelectedInfoType && (
                        <span className={styles.suCustomerLineBefore}>{t('Property Code: ')}</span>
                    )}
                    {!isSelectedInfoType && (
                        <span className={styles.suCustomerLineBefore}>-</span>
                    )}
                    {!isMainBannerLineType && (
                        <span className={styles.suCustomerLine}>{propertyCode}</span>
                    )}
                </div>
            )}
        </div>
    );
};
