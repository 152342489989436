import React from 'react';
import { ITextOption } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { enhanceState } from '@silkpwa/module/util/react-state-enhancer';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { clear, clearStyle, clearText } from './clear';
import {
    isValid,
    isValidColorSection,
    isValidFontSection,
    isValidPlacementSection,
    isValidTextSection,
} from './is-valid';
import { fontFamily } from './font-family';
import { colorValue } from './color-value';
import { extractState, nextPage } from '../util';

import EmbLineRule = Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface;

export class State extends React.Component<any, ITextOption> {
    private setIn: any;

    private updateIn: any;

    private readonly timer: Array<number>; // a timer array for delaying updating state

    constructor(props: any) {
        super(props);

        const { embroiderer } = props;
        const currentPageData: ITextOption = extractState(embroiderer, 'text');

        const initialLines = this.initLines(currentPageData);
        const { linesVisibility, embTextLines } = currentPageData;

        this.state = {
            ...currentPageData,
            lines: initialLines,
            embTextLines,
            linesVisibility: linesVisibility || this.initVisibleLines(initialLines, embTextLines),
        };

        this.state = {
            ...this.state,
            visibleLinesCount: this.visibleLinesCount,
            requiredRuleLines: this.requiredRuleLines,
        };

        this.setFont = this.setFont.bind(this);
        this.setLine = this.setLine.bind(this);
        this.setColor = this.setColor.bind(this);
        this.setPlacement = this.setPlacement.bind(this);
        this.setLiquidPixelPlacement = this.setLiquidPixelPlacement.bind(this);
        this.addLine = this.addLine.bind(this);
        this.removeLine = this.removeLine.bind(this);
        this.save = this.save.bind(this);
        this.saveTextOnly = this.saveTextOnly.bind(this);
        this.saveOption = this.saveOption.bind(this);
        this.clear = this.clear.bind(this);
        this.clearLines = this.clearLines.bind(this);
        this.clearStyles = this.clearStyles.bind(this);
        this.validateText = this.validateText.bind(this);
        this.timer = [];

        this.componentWillReceiveProps = this.performUpdates.bind(this);
    }

    get visibleLinesCount(): number {
        const { linesVisibility } = this.state;
        return linesVisibility.filter((isVisible: boolean) => isVisible).length ?? 0;
    }

    get nextLineIndex(): false|number {
        const { linesVisibility } = this.state;

        let nextLine: false|number = false;
        let found = false;
        linesVisibility.forEach((isVisible: boolean, index: number): void => {
            if (!found && !isVisible) {
                nextLine = index;
                found = true;
            }
        });

        return nextLine;
    }

    get canAddLine(): boolean {
        const { visibleLinesCount } = this;
        const { maxLines } = this.state;

        return visibleLinesCount < maxLines;
    }

    get canRemoveLine(): boolean {
        const { visibleLinesCount } = this;
        const { minLines } = this.state;

        return visibleLinesCount > minLines;
    }

    get requiredRuleLines(): EmbLineRule[] {
        const { embroiderer } = this.props;
        const textRulesPerLine: EmbLineRule[] = embroiderer.textEmbroideryRulesPerLine;
        const requiredRules: EmbLineRule[] = textRulesPerLine.filter((line: EmbLineRule) => !!line.is_required);
        return requiredRules;
    }

    get hasRequiredRules(): boolean {
        const { requiredRuleLines } = this;
        return !!requiredRuleLines.length;
    }

    get lastVisibleIndex(): number {
        const { linesVisibility } = this.state;
        let resultIndex = 0;

        linesVisibility.forEach((isLineVisible: boolean, index: number) => {
            if (isLineVisible) {
                resultIndex = index;
            }
        });

        return resultIndex;
    }

    setFont(font: string): () => void {
        return () => this.setIn(['font'], font);
    }

    setLine(lineIndex: number, text: string, needDelay = false): void {
        const { lines } = this.state;
        if (lineIndex === 0 && Object.keys(lines).length === 1 && !text.length) {
            this.updateIn(['lines'], (lines: any) => [...lines, ''], this.saveTextOnly);
            return;
        }

        if (!this.validateText(lineIndex, text)) return;

        if (this.timer[lineIndex]) {
            clearTimeout(this.timer[lineIndex]);
        }

        // @ts-ignore
        this.timer[lineIndex] = setTimeout(() => {
            this.setIn(['lines', lineIndex], text);
            this.saveTextOnly();
        }, needDelay ? 500 : 0);
    }

    setColor(color: string): () => void {
        return () => this.setIn(['color'], color);
    }

    setPlacement(placement: string): () => void {
        return () => this.setIn(['placement'], placement);
    }

    setLiquidPixelPlacement(placement: string): () => void {
        return () => this.setIn(['liquidPixelPlacement'], placement);
    }

    getCurrentState(): ITextOption {
        const { option } = this.props;
        const { lines, linesVisibility, embTextLines } = this.state;
        if (!isEqual(this.state, option)) {
            // eslint-disable-next-line Must use destructuring
            return {
                ...this.state,
                ...option,
                lines: lines || this.initLines(option),
                linesVisibility: linesVisibility || this.initVisibleLines(this.initLines(option), embTextLines),
            };
        }
        return this.state;
    }

    isTextSameInOtherLines(text: string, excludeIndex: number): boolean {
        const { lines, linesVisibility } = this.state;
        const isSame = (
            first: undefined|string,
            second: undefined|string,
        ): boolean => Boolean(first && first.length && second && second.length && first === second);

        const sameTextLines: string[] = lines.filter(
            (l: undefined|string, i: number) => i !== excludeIndex && linesVisibility[i] && isSame(text, l),
        );
        return Boolean(sameTextLines && sameTextLines.length);
    }

    validateText(lineIndex: number, text: string): boolean {
        const { textAllowed, linesVisibility } = this.state;

        if (text.length > this.maxLength(lineIndex)) return false;

        if (!textAllowed(text)) return false;

        const isSameTextExists = this.isTextSameInOtherLines(text, lineIndex);
        const isLineVisible = linesVisibility[lineIndex];

        return !(isLineVisible && isSameTextExists);
    }

    maxLength(lineNum: number): number {
        const { maxCharacters } = this.state;
        const maxLength = maxCharacters[lineNum];

        if (maxLength === 0) {
            return Number.MAX_SAFE_INTEGER;
        }

        return maxLength;
    }

    // eslint-disable-next-line class-methods-use-this
    initLines(data: ITextOption): string[] {
        const lines = [...data.lines];

        while (lines.length < data.minLines) lines.push('');

        return lines;
    }

    initVisibleLines(initialLines: string[], embTextLines: EmbLineRule[]): boolean[] {
        const { embroiderer } = this.props;
        const { isEmbroideryLockdown, isQuickView } = embroiderer;
        const { hasRequiredRules } = this;
        const lineNotEmpty = (
            lines: string[],
            index: number,
        ) => !!(lines && lines[index] && lines[index].length);

        let visibleLines: boolean[] = [];
        embTextLines.forEach((_, index: number): void => {
            if (initialLines.length === 1) {
                visibleLines[index] = index === 0;
            } else {
                visibleLines[index] = index === 0 ? true : lineNotEmpty(initialLines, index); // Always show the first line
            }
        });

        if (!isEmbroideryLockdown || !embTextLines.length || !hasRequiredRules) {
            return visibleLines;
        }

        visibleLines = [];
        embTextLines.forEach((rule: EmbLineRule, index: number): void => {
            const isRequired = !!rule.is_required;
            if (isQuickView) {
                visibleLines[index] = isRequired || lineNotEmpty(initialLines, index);
            } else {
                visibleLines[index] = isRequired;
            }
        });

        return visibleLines;
    }

    toggleLineVisibility(lineNumberIndex: number, show: boolean): void {
        const { linesVisibility } = this.state;
        linesVisibility[lineNumberIndex] = show;
    }

    addLine(): void {
        if (!this.canAddLine) {
            return;
        }

        const { nextLineIndex } = this;
        if (nextLineIndex === false) {
            return;
        }

        this.toggleLineVisibility(nextLineIndex, true);
        this.setIn(['lines', nextLineIndex], '');
    }

    removeLine(lineIndex: number, setTextError?: (textError: boolean) => void): () => void {
        return (): void => {
            if (!this.canRemoveLine) return;

            const { lastVisibleIndex } = this;
            if (lastVisibleIndex > 0) {
                this.toggleLineVisibility(lastVisibleIndex, false);
            }

            const { lines } = this.state;
            const newLines = lines.filter((_, index: number) => index !== lineIndex);
            newLines.push('');
            this.setState(s => ({
                ...s,
                lines: newLines,
            }));

            this.saveTextOnly();

            if (!setTextError) return;

            const { embroiderer } = this.props;
            const isValidSection = isValidTextSection(this.state, embroiderer);
            setTextError(!isValidSection);
        };
    }

    save(): void {
        const { embroiderer } = this.props;
        if (isValid(this.state, embroiderer)) {
            embroiderer.saveOption(this.state);
        }
    }

    saveTextOnly(): void {
        const { embroiderer } = this.props;
        embroiderer.saveOption(this.getCurrentState());
    }

    clear(): void {
        const cleared = clear(this.state);
        const { embTextLines } = cleared;
        const initialLines = this.initLines(cleared);
        this.setState({
            ...cleared,
            lines: initialLines,
            linesVisibility: this.initVisibleLines(initialLines, embTextLines),
        });
    }

    private saveOption(): void {
        const { embroiderer } = this.props;
        embroiderer.saveOption(this.state);
    }

    clearLines(): void {
        const cleared = clearText(this.state);
        this.setState({
            ...cleared,
        }, this.saveOption);
    }

    clearStyles(): void {
        const cleared = clearStyle(this.state);
        this.setState({
            ...cleared,
        }, this.saveOption);
    }

    performUpdates(newProps: any): void {
        const newOption: ITextOption = newProps.option;
        const isEqualStateOption = (oldOption: ITextOption, newOption: ITextOption) => {
            const keysToCompare = ['color', 'font', 'placement', 'lines'];
            const oldOptionSubset = pick(oldOption, keysToCompare);
            const newOptionSubset = pick(newOption, keysToCompare);
            return isEqual(oldOptionSubset, newOptionSubset);
        };
        if (newOption && !isEqualStateOption(this.state, newOption)) {
            this.setState((s: ITextOption) => ({
                ...s,
                ...newOption,
                lines: s.lines || this.initLines(newOption),
                linesVisibility: s.linesVisibility || this.initVisibleLines(this.initLines(newOption), s.embTextLines),
            }));
        }
    }

    render() {
        const { children, embroiderer } = this.props;

        if (typeof children !== 'function') return null;

        return children({
            embroideryPage: {
                ...this.state,
                replaceSubOption: (option: ITextOption, subOption = null): void => {
                    if (subOption) {
                        embroiderer.replaceView(option.type, subOption);
                    }
                },
                canRemoveLine: this.canRemoveLine,
                canAddLine: this.canAddLine,
                setFont: this.setFont,
                setLine: this.setLine,
                setColor: this.setColor,
                setPlacement: this.setPlacement,
                setLiquidPixelPlacement: this.setLiquidPixelPlacement,
                addLine: this.addLine,
                removeLine: this.removeLine,
                save: this.save,
                saveTextOnly: this.saveTextOnly,
                clear: this.clear,
                clearLines: this.clearLines,
                clearStyles: this.clearStyles,
                canSave: isValid(this.state, embroiderer),
                nextPage: nextPage(embroiderer, 'text'),
                fontFamily: fontFamily(this.state),
                colorValue: colorValue(this.state),
                validateText: this.validateText,
                isValidTextSection: isValidTextSection(this.state, embroiderer),
                isValidColorSection: isValidColorSection(this.state),
                isValidFontSection: isValidFontSection(this.state),
                isValidPlacementSection: isValidPlacementSection(this.state),
            },
        });
    }
}

enhanceState(State);
