import React, { createContext, useContext } from 'react';
import outline from 'ui/styles/outline-effect.css';
import { classes } from '@silkpwa/module/util/classes';
import { computeWidthStyle } from 'ui/util/compute-width';
import { ProductColorSelector } from '@silkpwa/module/react-component/product-color-selector';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { ProductConfiguratorModal } from 'ui/component/product-configurator-modal';
import { CardBody } from './card-body';
import { ColorSelector } from './color-selector';
import styles from './style.css';

const DisplayedContext = createContext<number>(4);
export const useDisplayed = () => useContext(DisplayedContext);

interface ICardBodyWrapperProps {
    colorsOpen: boolean;
}

export const CardBodyWrapper = ({
    children, colorsOpen,
}: React.PropsWithChildren<ICardBodyWrapperProps>) => {
    const displayed = useDisplayed();
    return (
        <div className={styles.boxWrap} style={computeWidthStyle(displayed)}>
            <div
                className={classes(styles.box, {
                    [outline.outlineOn]: colorsOpen,
                    [outline.outlineOff]: !colorsOpen,
                })}
                data-test="product-box"
            >
                {children}
            </div>
        </div>
    );
};

interface ProductCardProps {
    displayed: number;
    product: any;
    location: string;
}

export const InteractiveProductCard: React.FC<ProductCardProps> = ({
    displayed,
    product: originalProduct,
    location,
}) => (
    <DisplayedContext.Provider value={displayed}>
        <ProductColorSelector productId={originalProduct.id}>
            {({
                      colors,
                      product,
                      productImages,
                      colorProduct,
                  }) => (
                      <ProductConfiguratorModal productId={originalProduct.id}>
                          {({ setOpen: setModalOpen }) => (
                              <UseState initialState={false}>
                                  {([colorsOpen, setColorsOpen]) => (
                                      <CardBodyWrapper colorsOpen={colorsOpen}>
                                          <CardBody
                                              product={{
                                                ...product,
                                                name: colorProduct.name || product.name,
                                            }}
                                              images={productImages}
                                              location={location}
                                              openQuickView={() => setModalOpen(true)}
                                          />
                                          {/**
                                         * It is needed to pass a configurable product id to its child components in case if
                                         * there is a colorProduct with the `simple` type, which means this is a selected
                                         * color option. Further logic takes into account that product id in order to
                                         * memorize the choice on a PLP, see the logic in the file:
                                         * `chefworks-theme/src/ui/component/product/swatches/color-swatch/color-swatch.tsx`
                                         */}
                                          {!['relatedProducts',
                                            'upsellProducts',
                                            'crossSellProducts',
                                        ].includes(location) && (
                                            <ColorSelector
                                                rowSize={6}
                                                open={colorsOpen}
                                                colors={colors}
                                                toggle={() => setColorsOpen(!colorsOpen)}
                                                productId={+product.id}
                                            />
                                        )}
                                      </CardBodyWrapper>
                                )}
                              </UseState>
                        )}
                      </ProductConfiguratorModal>
                )}
        </ProductColorSelector>
    </DisplayedContext.Provider>
);
