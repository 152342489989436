import React, { useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { Divider } from 'ui/component/checkout/components/divider';
import styles from './style.css';

export enum EHighlightType {
    positive = 'positive',
}

export interface IMethodHeaderProps {
    identifier: string; // machine readable identifier for this header. No spacing, all lowercase - e.g. 'place-order'
    methodName?: string;
    open?: boolean;
    highlight?: EHighlightType;
    collapsedContent?: React.ReactNode;
    children?: React.ReactNode;
    bottomDivider?: boolean;
    onToggle?: () => void;
}

export const MethodHeader: React.FC<IMethodHeaderProps> = ({
    methodName = '',
    open = false,
    highlight = '',
    collapsedContent,
    children,
    identifier,
    bottomDivider = true,
    onToggle,
}) => {
    const t = usePhraseTranslater();
    const [isOpen, setIsOpen] = useState(open);

    const showHideMethod = () => {
        setIsOpen(!isOpen);
        if (onToggle) onToggle();
    };

    return (
        <div data-test={identifier}>
            {methodName !== '' && (
                <div
                    className={classes({
                        [styles.methodHeader]: true,
                        [styles[highlight]]: Boolean(highlight) && !isOpen,
                        [styles.active]: isOpen,
                    })}
                    onClick={showHideMethod}
                    onKeyDown={showHideMethod}
                    role="presentation"
                >
                    <span className={styles.title}>{t(methodName)}</span>
                    <i className={isOpen ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'} />
                </div>
            )}
            {isOpen && (
                <div className={styles.content}>
                    {children}
                </div>
            )}
            {!isOpen && collapsedContent && collapsedContent}
            {bottomDivider && <Divider />}
        </div>
    );
};
