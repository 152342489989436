import React from 'react';
import {
    EmbroideryRepository,
    EmbroideryConfig,
} from 'ui/component/embroidery-configurator/api/embroidery-repository';
import { IEmbroideryOption } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { TypeImpl } from '../pages/types';
import { types } from '../pages';
import { replaceView } from './replace-view';
import { LiquidPixelConfig } from '../components/preview/liquid-pixel/liquid-pixel-config';
import { combineOptions } from './combine-options';

import EmbLineRule = Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface;

// TODO: replace generic typings when we have more time
export interface EmbroidererProps extends EmbroideryConfig {
    options: IEmbroideryOption[];
    dataURL?: string;
    save: () => Promise<void>;
    saveEc: () => Promise<void>;
    download: () => Promise<void>;
    canDownload: boolean;
    clearAll: () => void;
    saveOption: (d) => void;
    viewStack: Array<object>;
    replaceView: (v, vD) => void;
    pushView: (v, vD) => void;
    popView: () => void;
    loadState: any;
    productColor: any;
    face: any;
    setFace: (f) => any;
    quantity: number;
    quantityState: {
        current: number;
        set: (v: number) => void;
        decrement: () => void;
        increment: () => void;
    };
    product: any;
    close: any;
    cannotSave: boolean;
    embroideryRepository: EmbroideryRepository;
    account: AccountState;
    isPriceReload: boolean;
    productPrice: number;
    embroideryPrice: number;
    subtotal: number;
    isQuickView: boolean;
    isEmbroideryLockdown: boolean;
    isSaveFailed: boolean;
}

export const addEmbroideryState = Component => (
    class EmbroideryState extends React.Component<any, any> {
        private _lastOptions: IEmbroideryOption[] = [];

        private _isSaving = false;

        /**
         * Initial state options list
         *
         * @private
         */
        private readonly initialStateOptions: {} = {
            options: [],
            face: '',
            viewStack: [{ view: 'start' }],
        };

        /**
         * Required default options list for `isEmbroideryLockdown` enabled
         *
         * @private
         */
        private readonly requiredInLockdown: string[] = [
            'text',
            'logos',
            'flags',
        ];

        constructor(props) {
            super(props);

            this.state = this.initialState;
            this.save = this.save.bind(this);
            this.saveEc = this.saveEc.bind(this);
            this.download = this.download.bind(this);
            this.clearAll = this.clearAll.bind(this);
            this.saveOption = this.saveOption.bind(this);
            this.pushView = this.pushView.bind(this);
            this.replaceView = this.replaceView.bind(this);
            this.popView = this.popView.bind(this);
            this.setFace = this.setFace.bind(this);

            this.componentDidMount = this.performUpdates.bind(this);
            this.componentDidUpdate = this.performUpdates.bind(this);
        }

        get initialState() {
            return this.initialStateOptions;
        }

        get initialRequiredOptionsList(): string[] {
            const { requiredInLockdown, hasRequiredRules } = this;
            return requiredInLockdown.filter((name: string) => name !== 'text' || hasRequiredRules);
        }

        get requiredLockdownOptions(): IEmbroideryOption[] {
            const self = this;
            const { options } = this.state;
            if (!options.length) {
                return [];
            }

            return options.filter(o => self.initialRequiredOptionsList.includes(o.type)) ?? [];
        }

        get validLockdownOptions(): IEmbroideryOption[] {
            return this.getLockdownOptions(true);
        }

        get invalidLockdownOptions(): IEmbroideryOption[] {
            return this.getLockdownOptions(false);
        }

        get firstInvalidOptionType(): string {
            let firstInvalidOption = '';
            const { invalidLockdownOptions } = this;
            if (invalidLockdownOptions.length && invalidLockdownOptions[0]) {
                firstInvalidOption = invalidLockdownOptions[0].type;
            }

            return firstInvalidOption;
        }

        get nextOption(): number {
            const { currentOption } = this.state;
            return currentOption + 1;
        }

        get cannotSave(): boolean {
            const { embroiderer } = this.props;
            const { isEmbroideryLockdown } = embroiderer;
            const { isEditing } = embroiderer;
            const { options } = this.state;
            const { requiredLockdownOptions, validLockdownOptions } = this;
            const allRequiredOptionsValid = validLockdownOptions.length === requiredLockdownOptions.length;

            if (isEmbroideryLockdown) {
                return !allRequiredOptionsValid;
            }

            const validOptions: IEmbroideryOption[] = options.filter(o => types[o.type].isValid(o, embroiderer));
            return !validOptions.length && !isEditing && !(validLockdownOptions.length && allRequiredOptionsValid);
        }

        get canDownload(): boolean {
            const { embroiderer } = this.props;
            const { options } = this.state;
            const { isEditing } = embroiderer;
            const validOptions: IEmbroideryOption[] = options.filter(o => types[o.type].isValid(o, embroiderer));
            const validLength = validOptions.length;
            const isValid: boolean = validLength > 1 ||
                (validLength === 1 && validOptions[0].type !== 'special-instructions');
            return isValid && !isEditing;
        }

        get embroideryPrice() {
            const { embroiderer } = this.props;
            const { options } = this.state;
            return options
                .filter((o) => {
                    if (o.type === 'logos') {
                        return types[o.type].isValid(o);
                    }
                    return types[o.type].isValid(o, embroiderer);
                })
                .map(o => types[o.type].price(
                    o,
                    embroiderer.embroideryState,
                    embroiderer.quantity,
                ))
                .reduce((acc, x) => acc + x, 0);
        }

        get productPrice() {
            const { embroiderer } = this.props;
            return (
                embroiderer.product.unformatted.price *
                embroiderer.quantity
            );
        }

        get textRulesPerLine(): EmbLineRule[] {
            const { embroiderer } = this.props;
            const textEmbLineRules: EmbLineRule[] = embroiderer.textEmbroideryRulesPerLine;
            return textEmbLineRules ?? [];
        }

        get requiredRuleLines(): EmbLineRule[] {
            const { textRulesPerLine } = this;
            const requiredRules: EmbLineRule[] = textRulesPerLine.filter((line: EmbLineRule) => !!line.is_required);
            return requiredRules ?? [];
        }

        get hasRequiredRules(): boolean {
            const { requiredRuleLines } = this;
            return !!requiredRuleLines.length;
        }

        getLockdownOptions(returnValidOptions: boolean): IEmbroideryOption[] {
            const { embroiderer } = this.props;
            const { isEmbroideryLockdown } = embroiderer;
            const { requiredLockdownOptions } = this;
            if (!isEmbroideryLockdown || !requiredLockdownOptions.length) {
                return [];
            }

            const result = returnValidOptions
                ? requiredLockdownOptions.filter(o => types[o.type].isValid(o, embroiderer))
                : requiredLockdownOptions.filter(o => !types[o.type].isValid(o, embroiderer));

            return result ?? [];
        }

        setFace(face: string) {
            return () => this.setState({ face });
        }

        performUpdates() {
            const { embroiderer } = this.props;
            if (embroiderer.options !== this._lastOptions) {
                this._lastOptions = embroiderer.options;

                const firstFace = embroiderer.faces[0];

                this.setState(s => ({
                    options: combineOptions(
                        s.options,
                        embroiderer.options,
                        embroiderer.isPriceReload,
                    ),
                    face: firstFace ? firstFace.name : '',
                }));
            }
        }

        private async validateAndSave(isEc = false) {
            const { embroiderer } = this.props;
            const { options } = this.state;
            const validOptions = options.filter(
                (option: IEmbroideryOption) => {
                    const { type } = option;
                    const theType: TypeImpl = types[type];
                    const { isValid } = theType;
                    if (isValid === undefined) return false;
                    /**
                     * In case of `Text` option we CANNOT bypass the empty text lines even though they are not
                     * required, because we cannot save not valid option in order to avoid non required options
                     * to be validated on BE (it will show an error).
                     */
                    return type === 'text'
                        ? isValid(option, embroiderer, false)
                        : isValid(option, embroiderer);
                },
            );

            let lpImages: {
                face: string;
                regular: string | undefined;
                large: string | undefined;
            }[] = [];

            const { liquidPixel } = embroiderer;
            const liquidPixelConfig = new LiquidPixelConfig({
                baseUrl: liquidPixel.baseUrl,
                productId: liquidPixel.liquidPixelSku || embroiderer.configurableSku,
                productColor: embroiderer.productColor,
                options,
            });
            lpImages = liquidPixelConfig.getAllAvailableImages();

            if (this.cannotSave) {
                embroiderer.setIsSaveFailed(true);
                this.navigateToInvalidSection();
                return;
            }

            embroiderer.setIsSaveFailed(false);

            if (!validOptions.length) {
                embroiderer.removeEmbroidery(() => embroiderer.afterSave());
                return;
            }

            if (this._isSaving) return;
            this._isSaving = true;

            try {
                if (isEc) {
                    await embroiderer.saveEc(validOptions, lpImages);
                } else {
                    await embroiderer.save(validOptions, lpImages);
                    embroiderer.afterSave();
                }
            } catch (e) {
                this.pushView('saving-error', e);
            }

            this._isSaving = false;
        }

        async saveEc(): Promise<void> {
            await this.validateAndSave(true);
        }

        async save(): Promise<void> {
            await this.validateAndSave(false);
        }

        async download(): Promise<void> {
            if (!this.canDownload) {
                return;
            }
            const { embroiderer } = this.props;
            const { options } = this.state;
            const validOptions = options.filter(o => types[o.type].isValid(o, embroiderer));
            const { liquidPixel } = embroiderer;
            const liquidPixelConfig = new LiquidPixelConfig({
                baseUrl: liquidPixel.baseUrl,
                productId: liquidPixel.liquidPixelSku || embroiderer.configurableSku,
                productColor: embroiderer.productColor,
                options,
            });
            const lpImages = liquidPixelConfig.getAllAvailableImages();
            try {
                await embroiderer.download(validOptions, lpImages, embroiderer.product.sku);
            } catch (e) {
                this.pushView('saving-error', e);
            }
        }

        navigateToInvalidSection(): void {
            const { firstInvalidOptionType } = this;
            if (!firstInvalidOptionType.length) {
                return;
            }

            const { embroiderer } = this.props;

            const invalidSectionId = `emb-section-${firstInvalidOptionType}`;
            const embSection = document.getElementById(invalidSectionId);
            if (!embSection) {
                return;
            }

            if (embroiderer.isEditing) {
                embSection.scrollIntoView();
                return;
            }

            const { top } = embSection.getBoundingClientRect();
            window.scrollTo(0, top + window.pageYOffset - 115);
        }

        clearAll() {
            this.setState(s => ({
                options: s.options.map(o => types[o.type].clear(o)),
            }));
        }

        saveOption(data: IEmbroideryOption) {
            this.setState(s => ({
                options: s.options.map(o => (o.type === data.type ? data : o)),
            }));
        }

        pushView(view, viewData) {
            this.setState(s => ({
                viewStack: [...s.viewStack, { view, viewData }],
            }));
        }

        popView() {
            const { viewStack } = this.state;
            if (viewStack.length <= 1) return;

            this.setState(s => ({
                viewStack: s.viewStack.filter((_, i) => i !== s.viewStack.length - 1),
            }));
        }

        replaceView(view, viewData) {
            this.setState(s => ({
                viewStack: replaceView(s.viewStack, { view, viewData }),
            }));
        }

        render() {
            const { options, face, viewStack } = this.state;
            const { embroiderer, children } = this.props;
            const { embroideryPrice, productPrice } = this;
            const subtotal = embroideryPrice + productPrice;

            const embroidererProps: EmbroidererProps = {
                options,
                dataURL: embroiderer.dataURL,
                save: this.save,
                saveEc: this.saveEc,
                download: this.download,
                canDownload: this.canDownload,
                clearAll: this.clearAll,
                saveOption: this.saveOption,
                viewStack,
                replaceView: this.replaceView,
                pushView: this.pushView,
                popView: this.popView,
                embroideryState: embroiderer.embroideryState,
                loadState: embroiderer.loadState,
                productColor: embroiderer.productColor,
                colorExclusions: embroiderer.colorExclusions,
                faces: embroiderer.faces,
                face: embroiderer.faces.filter(x => x.name === face)[0],
                setFace: this.setFace,
                quantity: embroiderer.quantity, // TODO: replace 'quantity' props usage with quantityState.current
                quantityState: embroiderer.quantityState,
                fonts: embroiderer.fonts,
                product: embroiderer.product,
                close: embroiderer.close,
                cannotSave: this.cannotSave,
                messages: embroiderer.messages,
                configurableSku: embroiderer.configurableSku,
                liquidPixel: embroiderer.liquidPixel,
                embroideryLogoEnabled: embroiderer.embroideryLogoEnabled,
                defaultLogo: embroiderer.defaultLogo,
                textEmbroideryHints: embroiderer.textEmbroideryHints,
                textEmbroideryRulesPerLine: embroiderer.textEmbroideryRulesPerLine,
                allowedLogoNumbers: embroiderer.allowedLogoNumbers,
                embroideryRepository: embroiderer.embroideryRepository,
                account: embroiderer.account,
                isPriceReload: embroiderer.isPriceReload,
                productPrice,
                embroideryPrice,
                subtotal,
                isQuickView: embroiderer.isQuickView,
                isEmbroideryLockdown: embroiderer.isEmbroideryLockdown,
                isSaveFailed: embroiderer.isSaveFailed,
            };

            return (
                <Component embroiderer={embroidererProps}>
                    {children}
                </Component>
            );
        }
    }
);
