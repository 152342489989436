import { gql, TypedDocumentNode } from '@apollo/client';

export const HELPFUL_VOTE_MUTATION: TypedDocumentNode = gql`
    mutation HELPFUL_VOTE_MUTATION($input: CreateHelpfulVoteInput!) {
        createHelpfulVote(input: $input) {
            success
            message
        }
    }
`;
