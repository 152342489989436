import React from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
    cartIdVar,
    cartVar,
    customerEmailVar,
    GetIsExplicitGuest,
    isGuestVar,
    isLoggedInVar,
    customerVar,
} from 'ui/page/checkout-page/checkout-state';
import { setSessionStorageData } from 'ui/util/session-storage';
import { LoadingImage } from 'ui/component/loading-image';
import { GET_CART, GET_CUSTOMER_CART } from 'graphql/cart/cart';
import { CustomerInfo } from './customer/customer-info';
import { LoginForm } from './customer/login-form';
import { EmailForm } from './customer/email-form';
import { IStepProps, StepHeader } from './step-header';
import styles from './style.css';

const CustomerStep = (step: IStepProps) => {
    const {
        stepNumber,
        open,
        complete,
        disabled,
    } = step;
    const cartId = useReactiveVar(cartIdVar);
    const loggedIn = useReactiveVar(isLoggedInVar);
    const email = useReactiveVar(customerEmailVar);
    const isGuest = useReactiveVar(isGuestVar);
    const customer = useReactiveVar(customerVar);

    // TODO: Move getCart to HOC
    const [getCart] = useLazyQuery(isGuest ? GET_CART : GET_CUSTOMER_CART, {
        variables: {
            cartId,
        },
        fetchPolicy: 'no-cache',
        onError: () => {
            // prevents console log
        },
        onCompleted: (data) => {
            if (data.cart) {
                cartVar(data.cart);
                if (cartId !== data.cart.id) {
                    setSessionStorageData('cartId', data.cart.id);
                    cartIdVar(data.cart.id);
                }
            }
        },
    });

    const updateCart = async (cartId: string | null = null) => {
        if (cartId !== null) {
            await getCart({ variables: { cartId } });
        } else {
            await getCart();
        }
    };

    return (
        <StepHeader stepNumber={stepNumber} stepName="Customer" isComplete={complete} open={open} disabled={disabled}>
            {!loggedIn && !email && <EmailForm />}
            {!loggedIn && email && (!isGuest && !GetIsExplicitGuest()) && <LoginForm updateCart={updateCart} />}
            {(loggedIn && !customer) && (
                <div className={styles.smallLoadingImage}>
                    <LoadingImage />
                </div>
            )}
            {((loggedIn && customer) || isGuest || GetIsExplicitGuest()) && email && <CustomerInfo />}
        </StepHeader>
    );
};
CustomerStep.stepKey = 'customer';

export { CustomerStep };
