import React from 'react';
import { RelatedProductsWidget } from 'ui/component/product/product-widget/related';

interface RelatedProductsProps {
    product: {
        id: number;
    };
}

export const RelatedProducts = ({ product }: RelatedProductsProps) => (
    <RelatedProductsWidget productId={product.id} />
);
