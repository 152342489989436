import React, { useState, useEffect, useRef } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { connectReviews } from '@silkpwa/module/react-component/connect-reviews';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { GoogleRecaptchaWidget } from '@silkpwa/module/google-recaptcha';
import { useLocation } from '@silkpwa/module/router/use-location';
import ReCAPTCHA from 'react-google-recaptcha';
import { ReviewRating } from './form/rating';
import { ReviewFormInput } from './form/textfield';
import { ReviewSizing } from './form/sizing';
import { ReviewWidth } from './form/width';
import { ReviewComments } from './form/comments';
import { ReviewRecommend } from './form/recommend';
import { Fields } from './fields';
import { ReviewFormClearBtn } from './buttons/clear-btn';
import { ReviewFormSubmitBtn } from './buttons/submit-btn';
import ThankYou from './thank-you/thank-you';
import { Uploader } from '../api/uploader';
import style from './style.css';

interface ReviewFormProps {
    product: {[key: string]: {url: string}};
    productId: number;
    addReview: (review: object, recaptchaCode: string | null) => NewReview;
    reviewAdded: boolean;
    addingReview: boolean;
    error: Error | null;
    resetReview: () => void;
    uploadImages: (data: FormData) => ImageUploadResult;
    uploadVideos: (data: FormData) => VideoUploadResult;
}

export interface ImageUploadResult {
    path?: string;
    url: string;
    title: string;
    caption: string;
    rotate: number;
}

export interface VideoUploadResult {
    path?: string;
    url: string;
    title: string;
    caption: string;
    poster: string;
    videoPosterPath: string;
    rotate: number;
}

export interface ReviewState {
    productId: number;
    productUrl: string;
    rating: string;
    title: string;
    sizing: string;
    width: string;
    detail: string;
    recommend: string;
    nickname: string;
    location: string;
    orderId: string | null;
    customerId: string | null;
    uploadedImageData: ImageUploadResult[];
    uploadedVideoData: VideoUploadResult[];
}

export interface NewReview {
    success: boolean;
    message: string;
}

const ReviewForm: React.FC<ReviewFormProps> = ({
    product,
    productId,
    addReview,
    addingReview,
    reviewAdded,
    error,
    resetReview,
    uploadImages,
    uploadVideos,
}) => {
    const scrollError = useRef(null);
    useEffect(() => {
        resetReview();
    }, [resetReview]);

    const t = usePhraseTranslater();
    const location = useLocation();
    const [orderId, setOrderId] = useState<string | null>(null);
    const [customerId, setCustomerId] = useState<string | null>(null);
    const [review, setFormData] = useState<ReviewState>({
        productId,
        productUrl: '',
        rating: '',
        title: '',
        sizing: '',
        width: '',
        detail: '',
        recommend: '',
        nickname: '',
        location: '',
        orderId,
        customerId,
        uploadedImageData: [],
        uploadedVideoData: [],
    });
    const [isUploading, setIsUploading] = useState(false);
    useEffect(() => {
        const hashParams = location.hash.split('?')[1];
        const params = new URLSearchParams(hashParams);
        const orderId = params.get('order_id');
        const customerId = params.get('customer_id');
        setOrderId(orderId);
        setCustomerId(customerId);

        setFormData(prevData => ({
            ...prevData,
            orderId,
            customerId,
        }));
    }, [location.hash]);
    const [errors, setErrors] = useState<FormErrors>({});
    const errorLength = Object.keys(errors).length;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [errorLength, reviewAdded, error]);

    interface FormErrors {
        rating?: string;
        title?: string;
        detail?: string;
        nickname?: string;
        location?: string;
    }

    const handleInputChange = (event) => {
        const {
            name,
            value,
        } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
        // @ts-ignore
        if (errors[name]) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: undefined,
            }));
        }
    };
    const [isVisible, setIsVisible] = useState(true);

    const display = (elementId) => {
        const element = document.getElementById(elementId) as HTMLDivElement;
        if (element) {
            element.style.display = isVisible ? 'block' : 'none';
            setIsVisible(true);
        }
    };

    const notDisplay = (elementId) => {
        const element = document.getElementById(elementId) as HTMLDivElement;
        if (element) {
            element.style.display = isVisible ? 'none' : 'block';
            setIsVisible(true);
        }
    };

    const minRecommendedMsgs = 200;
    const combinedOnChange = (event, targetMeter, maxChars, errorName) => {
        handleInputChange(event);
        const commentLength = event.target.value.length;
        const meter = (elementId) => {
            const element = document.getElementById(elementId);
            if (element) {
                const width = Math.min((commentLength / maxChars) * 100, 100);
                element.style.width = `${width}%`;
            }
        };
        meter(targetMeter);
        const alphaNumericPattern = /^[a-z0-9! "#$&',.:;@áéíóúñàèìòùâêîôûäëïöüçæœ]+$/i;
        const { value } = event.target;
        if (!alphaNumericPattern.test(value)) {
            display(errorName);
        } else {
            notDisplay(errorName);
        }

        const width = (commentLength / minRecommendedMsgs) * 100;
        if ((width > 0) && (width < 100)) {
            notDisplay('comments-description');
            display('comments-start');
            notDisplay('comments-limit');
            notDisplay('comments-middle');
        } else if ((width >= 100) && (width < 150)) {
            display('comments-middle');
            notDisplay('comments-start');
            notDisplay('comments-description');
            notDisplay('comments-limit');
        } else if (width >= 150) {
            display('comments-limit');
            notDisplay('comments-start');
            notDisplay('comments-description');
            notDisplay('comments-middle');
        } else {
            display('comments-description');
            notDisplay('comments-start');
            notDisplay('comments-limit');
            notDisplay('comments-middle');
        }
    };

    function elementsCounter(amount) {
        if (amount <= 1) {
            return [];
        }

        const result = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= amount; i++) {
            result.push(i);
        }

        return result;
    }

    const starsAmount = 5;
    const [clickedStar, setClickedStar] = useState(-1);
    const [hoveredStar, setHoveredStar] = useState(-1);

    const isClickedClass = (index) => {
        const isClicked = index <= clickedStar;
        return isClicked ? style.starV4100Filled : style.starV40Filled;
    };

    const isHoveredClass = (index) => {
        const isHovered = index <= hoveredStar;
        return isHovered ? style.starV4100Filled : style.starV40Filled;
    };

    const handleStarClick = (index) => {
        isClickedClass(index);
        setClickedStar(index);
    };

    const handleStarHover = (index) => {
        isHoveredClass(index);
        setHoveredStar(index);
    };

    const sizingAmount = 5;
    const widthAmount = 3;
    const recommendAmount = 2;

    const [selectedSizingIndex, setSelectedSizingIndex] = useState(null);
    const [selectedWidthIndex, setSelectedWidthIndex] = useState(null);
    // eslint-disable-next-line max-len
    const [selectedRecommendIndex, setSelectedRecommendIndex] = useState(null);
    const [sizingLabel, setSelectedSizingLabel] = useState('');
    const [widthLabel, setSelectedWidthLabel] = useState('');
    const [recommendLabel, setSelectedRecommendLabel] = useState('');

    const handleSelect = (event, index) => {
        const element = event.target;
        const elementAttribute = element.getAttribute('for');
        if (sizingLabel && elementAttribute === `sizing-${index}`) {
            const prevSelectedSizingElement =
                document.querySelector<HTMLElement>(`[for="${sizingLabel}"]`);
            prevSelectedSizingElement.style.backgroundColor = 'inherit';
            prevSelectedSizingElement.style.color = '#333';
            element.style.backgroundColor = '#0b7bc1';
            element.style.color = '#fff';
            setSelectedSizingIndex(index);
            setSelectedSizingLabel(`sizing-${index}`);
        } else if (widthLabel && elementAttribute === `width-${index}`) {
            const prevSelectedWidthElement =
                document.querySelector<HTMLElement>(`[for="${widthLabel}"]`);
            prevSelectedWidthElement.style.backgroundColor = 'inherit';
            prevSelectedWidthElement.style.color = '#333';
            element.style.backgroundColor = '#0b7bc1';
            element.style.color = '#fff';
            setSelectedWidthIndex(index);
            setSelectedWidthLabel(`width-${index}`);
        } else if (recommendLabel && elementAttribute === `recommend-${index}`) {
            const prevSelectedRecommendElement =
                document.querySelector<HTMLElement>(`[for="${recommendLabel}"]`);
            prevSelectedRecommendElement.style.backgroundColor = 'inherit';
            element.style.backgroundColor = '#a01d26';
            setSelectedRecommendIndex(index);
            setSelectedRecommendLabel(`recommend-${index}`);
        } else {
            const element = event.target;
            if (element.getAttribute('for') === `sizing-${index}`) {
                element.style.backgroundColor = '#0b7bc1';
                element.style.color = '#fff';
                setSelectedSizingIndex(index);
                setSelectedSizingLabel(`sizing-${index}`);
                display('clear-sizing-btn');
            }
            if (element.getAttribute('for') === `width-${index}`) {
                element.style.backgroundColor = '#0b7bc1';
                element.style.color = '#fff';
                setSelectedWidthIndex(index);
                setSelectedWidthLabel(`width-${index}`);
                display('clear-width-btn');
            }
            if (element.getAttribute('for') === `recommend-${index}`) {
                element.style.backgroundColor = '#a01d26';
                setSelectedRecommendIndex(index);
                setSelectedRecommendLabel(`recommend-${index}`);
                display('clear-recommend-btn');
            }
        }
    };

    const handleClearSelect = (e) => {
        const elementAttribute = e.target.getAttribute('data-clear');
        if (sizingLabel && elementAttribute === 'sizing') {
            e.preventDefault();
            const elementToClear =
                document.querySelector<HTMLElement>(`[for="${sizingLabel}"].active`);
            elementToClear.style.backgroundColor = 'inherit';
            elementToClear.style.color = '#333';
            setSelectedSizingLabel('');
            setFormData(prevData => ({
                ...prevData,
                // eslint-disable-next-line no-useless-computed-key
                ['sizing']: '',
            }));
            notDisplay('clear-sizing-btn');
        }
        if (widthLabel && elementAttribute === 'width') {
            e.preventDefault();
            const elementToClear =
                document.querySelector<HTMLElement>(`[for="${widthLabel}"].active`);
            elementToClear.style.backgroundColor = 'inherit';
            elementToClear.style.color = '#333';
            setSelectedWidthLabel('');
            setFormData(prevData => ({
                ...prevData,
                // eslint-disable-next-line no-useless-computed-key
                ['width']: '',
            }));
            notDisplay('clear-width-btn');
        }
        if (recommendLabel && elementAttribute === 'recommend') {
            e.preventDefault();
            const elementToClear =
                document.querySelector<HTMLElement>(`[for="${recommendLabel}"].active`);
            elementToClear.style.backgroundColor = 'inherit';
            elementToClear.style.color = '#333';
            setSelectedRecommendLabel('');
            setFormData(prevData => ({
                ...prevData,
                // eslint-disable-next-line no-useless-computed-key
                ['recommend']: '',
            }));
            notDisplay('clear-recommend-btn');
        }
    };

    const setLabelText = (text, index) => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= text.length; i++) {
            if (i === (index - 1)) {
                return text[i];
            }
        }
        return '';
    };

    const validateFormFields = () => {
        const newErrors: FormErrors = {};
        const fieldsToCheckReq: Array<keyof FormErrors> = ['title', 'nickname', 'location', 'rating', 'detail'];
        fieldsToCheckReq.forEach((fieldName) => {
            if (!review[fieldName]) {
                newErrors[fieldName] = `${fieldName.charAt(0)
                    .toUpperCase() + fieldName.slice(1)} is required`;
            }
        });
        const alphaNumericPattern = /^[a-z0-9! "#$&',.:;@áéíóúñàèìòùâêîôûäëïöüçæœ]+$/i;
        const fieldsToCheckAlphaNumeric: Array<keyof FormErrors> = ['title', 'nickname', 'location', 'detail'];
        fieldsToCheckAlphaNumeric.forEach((fieldName) => {
            if (fieldName in review && !alphaNumericPattern.test(review[fieldName]) && review[fieldName]) {
                newErrors[fieldName] = `${fieldName.charAt(0)
                    .toUpperCase() + fieldName.slice(1)} input must be alphanumeric`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const productUrl = product[productId]?.url;
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [captchaError, setCaptchaError] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        const recaptchaCode = recaptchaRef?.current?.getValue();
        if (!recaptchaCode) {
            setCaptchaError(true);
            throw new Error('ReCAPTCHA verification failed. Please try again.');
        }
        if (validateFormFields()) {
            await addReview(review, recaptchaCode);
            setCaptchaError(false);
        }
    };

    if (reviewAdded && !error && !addingReview) {
        return (
            <ThankYou
                productUrl={productUrl}
                rating={review.rating}
                title={review.title}
                detail={review.detail}
                sizing={review.sizing}
                width={review.width}
                nickname={review.nickname}
                recommend={review.recommend}
                images={review.uploadedImageData}
                videos={review.uploadedVideoData}
            />
        );
    }
    const handleRefresh = () => {
        window.location.reload();
    };
    const captchaTError = error && error.message.includes('E_BAD_REQUEST');

    if (error && !captchaTError) {
        return (
            <div className={style.errorNoReview}>
                Unable to submit review, please try again in a few minutes or
                {' '}
                <button type="submit" className={style.noReviewRefresh} onClick={handleRefresh}>
                    click here
                    {' '}
                    <>
                        {resetReview}
                    </>
                </button>
                {' '}
                to refresh the page. Thank you.
            </div>
        );
    }
    const handleImageUpload = (newImages) => {
        const flattenedImages = newImages.flat();
        setFormData(currentData => ({
            ...currentData,
            uploadedImageData: flattenedImages,
        }));
    };

    const handleVideoUpload = (newVideo) => {
        const flattenedVideos = newVideo.flat();
        setFormData(currentData => ({
            ...currentData,
            uploadedVideoData: flattenedVideos,
        }));
    };

    return (
        <div className={style.wrapper}>
            {errorLength > 0 && (
                <div className={style.errorSection} ref={scrollError}>
                    <span>
                        {`THERE ARE ${errorLength} ERRORS IN THIS FORM`}
                    </span>
                    <ul>
                        {errors.rating && <li>{errors.rating}</li>}
                        {errors.title && <li>{errors.title}</li>}
                        {errors.detail && <li>{errors.detail}</li>}
                        {errors.nickname && <li>{errors.nickname}</li>}
                        {errors.location && <li>{errors.location}</li>}
                    </ul>
                </div>
            )}
            <form className={style.war} onSubmit={handleSubmit}>
                <div id="warForm">
                    <p className={style.headerRequired}>
                        <abbr className={style.requiredIndicator} title="required">*</abbr>
                        <span>Required question</span>
                    </p>
                    <Fields className={style.formGroup}>
                        <legend className={style.controlLabel}>
                            <div className={style.errorWrapper}>
                                <span
                                    className={`${errors.rating ? style.inputErrorHeader : ''}`}
                                >
                                    {t('YOUR RATING')}
                                </span>
                                <abbr className={style.requiredIndicator} title="required">*</abbr>
                                <span className={`${errors.rating ? style.errorIcon : ''}`} />
                            </div>
                        </legend>
                        <div id="rating" className={style.rating}>
                            <div role="radiogroup" className={`${errors.rating ? style.inputError : ''}`}>
                                {elementsCounter(starsAmount)
                                    .map(index => (
                                        <ReviewRating
                                            index={index}
                                            review={review}
                                            t={t}
                                            isClickedClass={isClickedClass}
                                            isHoveredClass={isHoveredClass}
                                            handleStarClick={handleStarClick}
                                            handleStarHover={handleStarHover}
                                            handleInputChange={handleInputChange}
                                        />
                                    ))}
                            </div>
                        </div>
                    </Fields>
                    <ReviewFormInput
                        name="title"
                        value={review.title}
                        t={t}
                        combinedOnChange={combinedOnChange}
                        inputId="title-input"
                        labelTitle="REVIEW HEADLINE"
                        wrapperId="nickname"
                        placeholder="I would buy this product again and again"
                        error={errors.title}
                    />
                    <Fields className={classes(style.width, style.formGroup)} id="sizing">
                        <legend className={style.controlLabel}>
                            <div className=""><span>SIZING</span></div>
                            <div className={style.helperText}>Select one</div>
                        </legend>
                        <div
                            className={classes(
                                style.toggle,
                                style.btnGroup,
                                style.btnGroupRadioHorizontalLinked,
                            )}
                            role="radiogroup"
                        >
                            {elementsCounter(sizingAmount)
                                .map(index => (
                                    <ReviewSizing
                                        index={index}
                                        review={review}
                                        selectedSizingIndex={selectedSizingIndex}
                                        handleSelect={handleSelect}
                                        setLabelText={setLabelText}
                                        handleInputChange={handleInputChange}
                                    />
                                ))}
                        </div>
                        <ReviewFormClearBtn
                            elementId="clear-sizing-btn"
                            btnText="Clear Selection"
                            className={style.clearAllRadios}
                            handler={handleClearSelect}
                            dataClear="sizing"
                        />
                    </Fields>
                    <Fields className={classes(style.width, style.formGroup)} id="width">
                        <legend className={style.controlLabel}>
                            <div className=""><span>WIDTH</span></div>
                            <div className={style.helperText}>Select one</div>
                        </legend>
                        <div
                            className={classes(
                                style.toggle,
                                style.btnGroup,
                                style.btnGroupRadioHorizontalLinked,
                            )}
                            role="radiogroup"
                        >
                            {elementsCounter(widthAmount)
                                .map(index => (
                                    <ReviewWidth
                                        index={index}
                                        review={review}
                                        selectedWidthIndex={selectedWidthIndex}
                                        handleSelect={handleSelect}
                                        setLabelText={setLabelText}
                                        handleInputChange={handleInputChange}
                                    />
                                ))}
                        </div>
                        <ReviewFormClearBtn
                            elementId="clear-width-btn"
                            btnText="Clear Selection"
                            className={style.clearAllRadios}
                            handler={handleClearSelect}
                            dataClear="width"
                        />
                    </Fields>
                    <ReviewComments
                        review={review}
                        t={t}
                        combinedOnChange={e => combinedOnChange(e, 'meterComments', 200, `error-${t}`)}
                        minRecommendedMsgs={minRecommendedMsgs}
                        error={errors.detail}
                    />
                    <Fields className={classes(style.recommend, style.formGroup)} id="bottomline">
                        <legend className={style.controlLabel}>
                            <div className="">
                                <span>{t('BOTTOM LINE')}</span>
                            </div>
                            <div className={style.helperText}>{t('Select one')}</div>
                        </legend>
                        <div
                            className={classes(
                                style.toggle,
                                style.btnGroup,
                                style.btnGroupRadioHorizontalLinked,
                            )}
                            role="radiogroup"
                        >
                            {elementsCounter(recommendAmount)
                                .map(index => (
                                    <ReviewRecommend
                                        index={index}
                                        review={review}
                                        selectedRecommendIndex={selectedRecommendIndex}
                                        handleSelect={handleSelect}
                                        setLabelText={setLabelText}
                                        handleInputChange={handleInputChange}
                                    />
                                ))}
                        </div>
                        <ReviewFormClearBtn
                            elementId="clear-recommend-btn"
                            btnText="Clear Selection"
                            className={style.clearAllRadios}
                            handler={handleClearSelect}
                            dataClear="recommend"
                        />
                    </Fields>
                    <ReviewFormInput
                        name="nickname"
                        value={review.nickname}
                        t={t}
                        combinedOnChange={combinedOnChange}
                        inputId="nickname-input"
                        labelTitle="NICKNAME"
                        wrapperId="nickname"
                        placeholder="Ex. Sam (don't use your real name)"
                        error={errors.nickname}
                    />
                    <ReviewFormInput
                        name="location"
                        value={review.location}
                        t={t}
                        combinedOnChange={combinedOnChange}
                        inputId="location-input"
                        labelTitle="LOCATION"
                        wrapperId="location"
                        placeholder="Ex. San Jose, CA"
                        error={errors.location}
                    />
                    <Uploader
                        initialReview={review}
                        uploadImages={uploadImages}
                        uploadVideos={uploadVideos}
                        onImageUpload={handleImageUpload}
                        onVideoUpload={handleVideoUpload}
                        setIsUploading={setIsUploading}
                    />
                </div>
                <div className={style.captcha}>
                    <GoogleRecaptchaWidget
                        form="review"
                        recaptchaRef={recaptchaRef}
                    />
                    {captchaError && (
                        <div className={style.captchaError}>{t('Please Verify Google Recaptcha.')}</div>
                    )}
                </div>
                {!addingReview && (
                    <div className={style.submit}>
                        <div className={style.footer}>
                            <ReviewFormSubmitBtn
                                btnText="Submit Review"
                                className={classes(
                                    style.button,
                                    style.accessibleBtn,
                                )}
                                onSubmit={handleSubmit}
                                disabled={isUploading}
                            />
                        </div>
                    </div>
                )}
                {addingReview && (
                    <div>
                        <i className={style.spinner} />
                        <span className={style.reviewCommentsLimit}>Verifying and Submitting Review</span>
                    </div>
                )}
            </form>
        </div>
    );
};

const ConnectedReviewForm = connectReviews(ReviewForm);

export { ConnectedReviewForm as ReviewForm };
