import React from 'react';
import { PaymentForm } from './payment-form';

export interface IPaymentProps {
    setBeforePlaceOrder: (beforePlaceOrder: () => Promise<any>) => void;
    setAfterPlaceOrder: (afterPlaceOrder: () => Promise<any>) => void;
}

export const Payment =
    ({ setBeforePlaceOrder, setAfterPlaceOrder }: IPaymentProps) => (
        <PaymentForm
            setBeforePlaceOrder={setBeforePlaceOrder}
            setAfterPlaceOrder={setAfterPlaceOrder}
        />
    );
