import React, { useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { useMutation, ApolloError } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ISubscribeEmailToNewsletterOutput, SUBSCRIBE_EMAIL_TO_NEWSLETTER } from 'graphql/customer';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import styles from './style.css';

export const Newsletter = () => {
    const t = usePhraseTranslater();
    const [subscribe, { loading }] = useMutation(SUBSCRIBE_EMAIL_TO_NEWSLETTER);
    const [email, setEmail] = useState<string>('');
    const [errors, setErrors] = useState<string[]>([]);
    const [thanks, setThanks] = useState<boolean>(false);

    const onError = ({ graphQLErrors }: ApolloError): void => {
        setThanks(false);
        const errorMessages: string[] = graphQLErrors.map((graphQLError) => {
            const { message } = graphQLError;
            return message;
        });
        setErrors(errorMessages);
    };

    const onCompleted = (data: ISubscribeEmailToNewsletterOutput): void => {
        const { subscribeEmailToNewsletter } = data;
        const { status } = subscribeEmailToNewsletter;
        if (status !== '') {
            setErrors([]);
            setThanks(true);
            return;
        }

        setErrors([t('Something went wrong while subscribing your email to the newsletter. Please, try again later.')]);
        setThanks(false);
    };

    const handleSubmit = () => {
        setErrors([]);
        setThanks(false);
        subscribe({
            variables: {
                email,
            },
            onError,
            onCompleted,
        }).then();
    };
    return (
        <div className={styles.newsletter}>
            <form className={fStyles.form}>
                <span className={styles.title}>{t('Get Exclusive News & Offers!')}</span>
                <div className={fStyles.formField}>
                    <input
                        type="text"
                        name="email"
                        placeholder={t('email address')}
                        onChange={event => setEmail(event.currentTarget.value)}
                    />
                </div>
                {errors.length > 0 && errors.map(
                    (error: string) => (
                        <div className={classes(fStyles.error, styles.message)}>{t(error)}</div>
                    ),
                )}
                {thanks && (
                    <div className={classes(styles.thanks, styles.message)}>{t('Thanks for signing up!')}</div>
                )}
                <button
                    type="button"
                    className={fStyles.checkoutButton}
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {t('Submit')}
                </button>
            </form>
        </div>
    );
};
