import React from 'react';
import { calculateDiscountPercentage } from 'ui/util/validator-helper';
import { useAccount } from '@silkpwa/module/account';
import { usePriceRounder } from '@silkpwa/module/ecommerce-catalog/config';

import styles from './styles.css';

interface IOriginalPriceProps {
    product: {
        unformatted: {
            price: number;
            msrpPrice: number;
            originalPrice: number;
            minPrice: number;
        };
        minPrice: string;
    }; // TODO: update type
    calculatedOriginalPrice: number | undefined;
}

const DiscountMessage: React.FC<IOriginalPriceProps> = ({
    product,
    calculatedOriginalPrice,
}) => {
    const account = useAccount();
    const roundPrice = usePriceRounder();
    const originalPrice = roundPrice(calculatedOriginalPrice || product.unformatted.originalPrice);
    const price = roundPrice(product.unformatted.price);
    const msrpPrice = roundPrice(product.unformatted.msrpPrice);
    if (!price || originalPrice <= price) {
        if (msrpPrice && !(msrpPrice <= price)) {
            return (
                <div
                    data-test="original-price"
                    data-original-price={originalPrice}
                    data-price={price}
                    className={styles.discountNumber}
                >
                    {calculateDiscountPercentage(msrpPrice, product.unformatted.minPrice)}
                </div>
            );
        }
        return null;
    }
    const { info } = account;
    const {
        extensionAttributes: ac,
    } = info;
    const portalData = ac?.portal_data;
    const hideStrikethroughPrice =
        Boolean(portalData?.is_active && portalData.code && portalData.hide_strikethrough_price);
    if (hideStrikethroughPrice) {
        return null;
    }
    return (
        <div
            data-test="original-price"
            data-original-price={originalPrice}
            data-price={price}
            className={styles.discountNumber}
        >
            {calculateDiscountPercentage(originalPrice, product.unformatted.minPrice)}
        </div>
    );
};

export default DiscountMessage;
