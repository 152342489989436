import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IImgAttributes } from '../../title-icon/title-icon';
import { RewardsItem } from './rewards-item';
import styles from './style.css';

interface AccordionItemProps {
    img?: string|IImgAttributes;
    text: string;
    tierText: string;
    type: string;
    isActive: boolean;
    toggle: () => void;
    style: {};
    children: React.ReactNode;
}
export const RewardsAccordionItem: React.FC<AccordionItemProps> = (
    {
        img,
        text,
        tierText,
        type,
        isActive,
        toggle,
        style,
        children,
    },
) => (
    <li className={styles.accordionItemWrapper}>
        <RewardsItem
            img={img}
            text={text}
            isActive={isActive}
            toggle={toggle}
            tierText={tierText}
            style={style}
            className={classes(styles.accordionItemHeader, {
                [styles.bronze]: type === 'bronze',
                [styles.silver]: type === 'silver',
                [styles.gold]: type === 'gold',
                [styles.ruby]: type === 'ruby',
                [styles.diamond]: type === 'diamond',
            })}
        />
        {isActive && (
            <div className={styles.content}>
                {children}
            </div>
        )}
    </li>
);
