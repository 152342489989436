import React, { useEffect, useState } from 'react';
import { MainColumn } from 'ui/component/main-column';
import { useQuery } from '@apollo/client';
import { FETCH_RELATED_PRODUCTS } from 'graphql/related-products/fetchRelatedProducts';
import screenSwitch from 'ui/styles/screen-switch.css';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import styles from './style.css';
import { FullProductSlider as ProductSlider } from '../product-slider';

interface UpsellProductsWidgetImplProps {
    productId: number;
}

interface RelatedProductItem {
    id: number;
    sku: string;
    name: string;
    'url_key'?: string;
    'type_id': string;
}

interface RelatedProductsByStore {
    'total_count': number;
    items: RelatedProductItem[];
}

interface RelatedProductsData {
    relatedProductsByStore: RelatedProductsByStore;
}

const UpsellProductsWidgetImpl = ({ productId }: UpsellProductsWidgetImplProps) => {
    const config = useConfig();

    const type = 'upsell';
    const storeId = config?.store_config?.current_store?.id;

    const { error, data, loading } =
        useQuery<RelatedProductsData>(FETCH_RELATED_PRODUCTS, {
            variables: {
                type,
                productId,
                storeId,
            },
            skip: !productId || !storeId,
        });

    const [upsellProducts, setUpsellProducts] = useState<RelatedProductItem[]>([]);

    useEffect(() => {
        if (data?.relatedProductsByStore?.items) {
            setUpsellProducts(data.relatedProductsByStore.items);
        }
    }, [data]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error || upsellProducts.length === 0) {
        return null;
    }

    return (
        <MainColumn className={styles.upsellProductsMainColumn}>
            <div className={styles.title}>{config.upsell_block_title}</div>
            <div className={screenSwitch.showOnDesktop}>
                <ProductSlider items={upsellProducts} show={5} location="upsellProducts" />
            </div>
            <div className={`${screenSwitch.showOnLargeMobile} ${screenSwitch.showOnMediumMobile}`}>
                <ProductSlider items={upsellProducts} show={3} location="upsellProducts" />
            </div>
            <div className={screenSwitch.showOnSmallMobile}>
                <ProductSlider items={upsellProducts} show={2} location="upsellProducts" />
            </div>
            <div className={screenSwitch.showOnVerySmallMobile}>
                <ProductSlider items={upsellProducts} show={1} location="upsellProducts" />
            </div>
        </MainColumn>
    );
};

export const UpsellProductsWidget = UpsellProductsWidgetImpl;
