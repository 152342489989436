import { Http } from '@silkpwa/module/util/api/http';
import { ModuleCreator } from '@silkpwa/redux';

export interface IReviewsRepository {
    _selectors: {[key: string]: Function};

    uploadImages(data: any): any;

    uploadVideos(data: any): any;
}

export const ReviewsRepositoryCreator = new ModuleCreator({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return <IReviewsRepository>{
            async uploadImages(data) {
                return magentoAPI.post('/review-upload-media', {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    data,
                });
            },
            async uploadVideos(data) {
                return magentoAPI.post('/review-upload-media', {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    data,
                });
            },
        };
    },
});
