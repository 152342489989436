import { gql, TypedDocumentNode } from '@apollo/client';

export const GET_PROMOTION_BANNER: TypedDocumentNode = gql`
    query GET_PROMOTION_BANNER($storeId: Int!) {
        getPromotionBanner(storeId: $storeId) {
            content,
            current_time_stamp,
            start_time_stamp,
            end_time_stamp,
        }
    }
`;
