import React from 'react';
import { usePriceRounder } from '@silkpwa/module/ecommerce-catalog/config';
import { useAccount } from '@silkpwa/module/account';
import { classes } from '@silkpwa/module/util/classes';
import { PlaceholderSentence } from '../../../placeholders/sentence';
import styles from './style.css';

// eslint-disable-next-line max-len
import ChefworksLoyaltyProgramRewardsPointPdpDataInterface = Magento.Definitions.ChefworksLoyaltyProgramRewardsPointPdpDataInterface;

function getPlpRewards(
    plpRewardsDetails: ChefworksLoyaltyProgramRewardsPointPdpDataInterface,
): string {
    let plpRewardsMessage = '';

    if (plpRewardsDetails) {
        const {
            visible_on_plp: isVisibleOnPlp,
            plp_text: plpText = '',
            point_display: isPointDisplay,
            message = '',
            points = '',
        } = plpRewardsDetails;

        if (isVisibleOnPlp && isPointDisplay) {
            plpRewardsMessage = plpText || message;
            return plpRewardsMessage.replace(/{{REWARDS}}/g, points);
        }
    }
    return plpRewardsMessage;
}

function hasPrice(product: any): boolean {
    return !!product.originalProduct?.prices?.configured_price.amount;
}

export const ProductPrice = (
    { theme, product, isMsrpShowEnable }:
    { theme: any; product: any; isMsrpShowEnable?: boolean },
) => {
    const account = useAccount();
    const roundPrice = usePriceRounder();
    const originalPrice = roundPrice(product.unformatted.originalPrice);
    const productPrice = roundPrice(product.unformatted.price);
    const msrpPrice = roundPrice(product.unformatted.msrpPrice);
    let showDiscountStyle = false;
    if (!productPrice || originalPrice <= productPrice) {
        if (msrpPrice && !(msrpPrice <= productPrice)) {
            showDiscountStyle = true;
        }
    }
    const { info } = account;
    const {
        extensionAttributes: ac,
    } = info;
    const portalData = ac?.portal_data;
    const hideStrikethroughPrice =
        Boolean(portalData?.is_active && portalData.code && portalData.hide_strikethrough_price);
    if (hideStrikethroughPrice) {
        showDiscountStyle = false;
    }
    const price = (() => {
        if (!hasPrice(product)) {
            return '';
        }
        if (product.maxPrice === product.minPrice) {
            return product.minPrice;
        }
        if (isMsrpShowEnable) {
            const currencySymbol = product.minPrice[0];
            const [dollars, cents] = product.unformatted.minPrice.toFixed(2).split('.');
            return (
                <div className={styles.mnPriceStyle}>
                    <span className={styles.mnPriceDollarStyle}>
                        {currencySymbol}
                        {dollars}
                    </span>
                    <span className={styles.mnPriceCentsStyle}>{cents}</span>
                </div>
            );
        }
        return `${product.minPrice} - ${product.maxPrice}`;
    })();

    if (product.arePricesInvalidated) {
        return (
            <PlaceholderSentence className={styles.placeholderPrice} />
        );
    }
    /* The product description can contain HTML, so we need to
    use dangerouslySetInnerHTML. This doesn't introduce an XSS
    vulnerability because it can only be set by CW employees */

    /* eslint-disable react/no-danger */
    return (
        <div
            className={classes(
                theme.productPrice, {
                    [styles.discountStyleCnt]: showDiscountStyle,
                }, 'weglot-exclude',
            )}
            data-test="product-box-price"
        >
            {price}
            {getPlpRewards(product.rewardsData) ? (
                <div
                    data-test="loyalty_message_display"
                    dangerouslySetInnerHTML={{
                        __html: getPlpRewards(product.rewardsData),
                    }}
                    className={styles.pdpRewardsMessage}
                />
            ) : ''}
        </div>
    );
};
