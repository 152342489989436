import React from 'react';
import { LogoDataObject } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state/add-embroidery-state';
import { showLogosOnFile } from 'ui/component/embroidery-configurator/pages/logos/util/show-logos-on-file';
import { showUpload } from 'ui/component/embroidery-configurator/pages/logos/util/show-upload';
import { getAllowedLogosForLine } from 'ui/component/embroidery-configurator/pages/logos/util/get-allowed-logos-for-line';
import {
    getAccountLogoAllowedForLine,
} from 'ui/component/embroidery-configurator/pages/logos/util/get-account-logo-allowed-for-line';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { LogoOnFile } from '../logo-on-file';
import { LogoUpload } from '../logo-upload';
import styles from './style.css';

interface ILogoActionsProp {
    logo: LogoDataObject;
    logoNum: number;
    embroiderer: EmbroidererProps;
    embroideryPage: any; // TODO: I'm not sure how to add an interface for this - will need to come back to it
}

export const LogoActions = ({
    logo,
    logoNum,
    embroiderer,
    embroideryPage,
}: ILogoActionsProp) => {
    const t = usePhraseTranslater();
    const allowedLogosOnFile = getAllowedLogosForLine(logoNum + 1, embroiderer);
    const accountLogoAllowed = getAccountLogoAllowedForLine(logoNum + 1, embroiderer);
    return (
        <>
            {showLogosOnFile(logoNum + 1, embroiderer) ? (
                <LogoOnFile
                    logo={logo}
                    logoNum={logoNum}
                    embroiderer={embroiderer}
                    embroideryPage={embroideryPage}
                    allowedLogos={allowedLogosOnFile}
                    accountLogoAllowed={accountLogoAllowed}
                />
            ) : (
                <div className={styles.noLogoData}>
                    {t('You currently do not have any on-file logos to select.')}
                </div>
            )}
            {showUpload(logoNum + 1, embroiderer) && (
                <LogoUpload
                    logo={logo}
                    embroideryPage={embroideryPage}
                />
            )}
        </>
    );
};
