import React from 'react';
import { getStylesFromString } from '../util/html-parser';

export interface IImgAttributes {
    alt: string;
    height: string;
    src: string;
    style: string;
    width: string;
}

interface ITitleIconParams {
    img?: string|IImgAttributes;
    className?: string;
}

export const TitleIcon: React.FC<ITitleIconParams> = (
    {
        img,
        className,
    },
) => {
    if (!img) {
        return null;
    }

    return (
        <>
            {(typeof img === 'string') && (
                <img className={(className || '')} src={img} alt="" />
            )}
            {(typeof img === 'object') && (
                <img
                    className={(className || '')}
                    src={img.src}
                    alt={img.alt}
                    height={img.height}
                    width={img.width}
                    style={getStylesFromString(img.style)}
                />
            )}
        </>
    );
};
