import { Container } from '@silkpwa/redux';
import { MetaDataLayer } from './data-layer';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

const loadMetaDataLayer = (container: Container) => {
    container.bind('metaDataLayer', MetaDataLayer);
    container.bind('getMetaConfig', (config: SilkRestappDataConfigInfoInterface) => ({
        enabled: config.extension_attributes?.meta_conversion_config?.meta_conversion_enabled,
        productEnabled: config.extension_attributes?.meta_conversion_config?.meta_product_tracking_enabled,
        categoryEnabled: config.extension_attributes?.meta_conversion_config?.meta_category_tracking_enabled,
        cartTrackingEnabled: config.extension_attributes?.meta_conversion_config?.meta_cart_tracking_enabled,
        wishlistTrackingEnabled: config.extension_attributes?.meta_conversion_config?.meta_wishlist_tracking_enabled,
        searchTrackingEnabled: config.extension_attributes?.meta_conversion_config?.meta_search_tracking_enabled,
        registrationTrackingEnabled:
        config.extension_attributes?.meta_conversion_config?.meta_registration_tracking_enabled,
        embroideryTrackingEnabled:
        config.extension_attributes?.meta_conversion_config?.meta_embroidery_tracking_enabled,
        initCheckoutTrackingEnabled:
        config.extension_attributes?.meta_conversion_config?.meta_init_checkout_tracking_enabled,
        portalEnabled:
        config.extension_attributes?.meta_conversion_config?.meta_portal_tracking_enabled,
    }));
};

export { loadMetaDataLayer };
