import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { findLogoRulePerLine } from './find-logo-rule-per-line';

import IPRuleLogoEmb = Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleLogoEmbInterface;

/**
 * LineNumber > 0
 * @param lineNumber
 * @param embroiderer
 */
export const getAllowedLogosForLine = (
    lineNumber: number,
    embroiderer: EmbroidererProps,
): string[] => {
    const foundLogoLine: IPRuleLogoEmb|undefined = findLogoRulePerLine(lineNumber, embroiderer);
    if (!foundLogoLine) {
        return [];
    }

    const { logo_numbers_array: logoNumbersArray } = foundLogoLine;
    return logoNumbersArray;
};
