import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { connectCompare } from '@silkpwa/module/react-component/connect-compare';
import { connectWishlist } from '@silkpwa/module/react-component/connect-wishlist';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import styles from './style.css';

interface HoverRelatedIconsProps {
    openQuickView: () => void;
    className: string;
    buttonText: string;
    isButtonOnTop?: boolean;
}

const HoverRelatedIconsStyled: React.FC<HoverRelatedIconsProps> = ({
    className,
    openQuickView,
    buttonText,
    isButtonOnTop,
}) => {
    const t = usePhraseTranslater();
    return (
        <div className={classes(styles.buttonIconsWrap, {
            [className]: className,
            [styles.buttonOnTopStyle]: isButtonOnTop,
            [styles.buttonStyle]: !isButtonOnTop,
        })}
        >
            <AccessibleButton
                tag="div"
                data-test={t('Add')}
                onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                }}
                action={openQuickView}
                title={buttonText}
                className={classes(styles.iconRelativeWrap, className)}
            >
                <span>{buttonText}</span>
            </AccessibleButton>
        </div>
    );
};

export const HoverRelatedIcons = connectWishlist(connectCompare(HoverRelatedIconsStyled));
