import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { useAccount } from '@silkpwa/module/account';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { SKU } from './sku';
import { OriginalPrice } from './original-price';
import { Price } from './price';
import { ProductMessage } from './product-message';
import DiscountMessage from './discount-message';
import { PlaceholderSentence } from '../../placeholders/sentence';
import styles from './styles.css';

interface IBaseProductInfoProps {
    product: any; // TODO: update type
    calculatedPrice: number | string | undefined;
    calculatedOriginalPrice: number | undefined;
    isQuickView?: boolean;
}

interface IProductInfoProps extends IBaseProductInfoProps {
    dynamicSku: string | undefined;
}

export function isSpecialPriceCustomer(account: AccountState): boolean {
    if (account.info.extensionAttributes?.special_price_flag != null) {
        return account.info.extensionAttributes.special_price_flag;
    }
    return false;
}

export const ProductPrices: React.FC<IBaseProductInfoProps> = ({
    product,
    calculatedPrice,
    calculatedOriginalPrice,
    isQuickView,
}) => {
    const account = useAccount();
    const storeConfig = useConfig();
    const isSpecialPrice = isSpecialPriceCustomer(account);
    if (product.arePricesInvalidated) {
        return (
            <PlaceholderSentence className={styles.placeholderPrice} dataTest="price-updating" />
        );
    }

    if (product.type === 'giftcard') return null;

    const isMsrpShowEnabled = !isSpecialPrice && !!storeConfig.extension_attributes?.cw_display_msrp;

    return (
        <>
            { isMsrpShowEnabled ? (
                <div className={styles.pricePdPCnt}>
                    <div className={styles.priceTopCnt}>
                        <DiscountMessage product={product} calculatedOriginalPrice={calculatedOriginalPrice} />
                        <Price
                            product={product}
                            calculatedPrice={calculatedPrice}
                            isQuickView={isQuickView}
                            isMsrpShowEnabled={isMsrpShowEnabled}
                        />
                    </div>
                    <OriginalPrice
                        product={product}
                        calculatedOriginalPrice={calculatedOriginalPrice}
                        isMsrpShowEnabled={isMsrpShowEnabled}
                    />
                </div>
            ) : (
                <>
                    <OriginalPrice product={product} calculatedOriginalPrice={calculatedOriginalPrice} />
                    <Price product={product} calculatedPrice={calculatedPrice} isQuickView={isQuickView} />
                </>
            )}
        </>
    );
};

export const ProductInfo: React.FC<IProductInfoProps> = ({
    product,
    calculatedPrice,
    calculatedOriginalPrice,
    dynamicSku,
    isQuickView,
}) => (
    <div>
        <h1
            data-test="product-name"
            className={classes(styles['product-name'], {
                [styles.quickView]: isQuickView,
            })}
        >
            {product.name}
        </h1>
        <SKU product={product} dynamicSku={dynamicSku} />
        <div className={styles.inline}>
            <ProductPrices
                product={product}
                calculatedPrice={calculatedPrice}
                calculatedOriginalPrice={calculatedOriginalPrice}
                isQuickView={isQuickView}
            />
        </div>
        <ProductMessage product={product} />
    </div>
);
