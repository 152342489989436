export const getLocalMediaURL = (image: string, pathToMedia: string): string => {
    const params = {
        set: '',
        seed: '',
        sink: '',
        call: '',
    };
    const queryString = new URL(image).search.substring(1); // Get the query string after '?'
    if (queryString) {
        queryString.split('&').forEach((pair) => {
            const [key, value] = pair.split('=');
            // @ts-ignore
            params[decodeURIComponent(key)] = decodeURIComponent(value || '');
        });
    }
    // @ts-ignore
    const setParams = params.set ? params.set.replace(/\|/g, '_').replace(/-/g, '--') : '';
    const paramsValues = setParams.split(',');
    const value = [];

    paramsValues.forEach((paramsValue: string) => {
        const matches = paramsValue.match(/(\w+)\[(.*?)\]/);
        if (matches) {
            value.push(matches[2] !== '' ? matches[2] : matches[1]);
        }
    });

    if (params.seed) {
        value.push(params.seed);
    }

    if (value.length === 0) {
        value.push(btoa(image));
    }

    const fileName = value.join('-');
    return `${pathToMedia}${fileName}.jpg`;
};
