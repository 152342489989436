import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { TitleIcon, IImgAttributes } from '../../title-icon/title-icon';
import styles from './style.css';

interface RewardsItemProps {
    img?: string|IImgAttributes;
    text: string;
    isActive: boolean;
    toggle: () => void;
    style: {};
    tierText?: string;
    className?: string;
}

export const RewardsItem: React.FC<RewardsItemProps> = (
    {
        img,
        text,
        isActive,
        toggle,
        tierText,
        style,
        className,
    },
) => (
    <AccessibleButton
        tag="div"
        action={toggle}
        className={classes(styles.rewardsItem, (className || ''))}
        style={style}
    >
        {img && (
            <TitleIcon
                img={img}
                className={styles.accordionIcon}
            />
        )}
        <span className={styles.accordionTitle}>{text}</span>
        {tierText && (
            <span className={styles.accordionTitleLower}>{tierText}</span>
        )}
        <i
            className={classes(
                'material-icons',
                styles.arrow,
            )}
        >
            {isActive ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }
        </i>
    </AccessibleButton>
);
