import React from 'react';
import { LogoDetailResponseData } from 'ui/component/embroidery-configurator/api/embroidery-repository';
import { LogoListItem } from './logo-list-item';
import { SelectedState } from './logo-on-file-selector';
import { useSearch } from './hooks/useSearch';
import style from './style.css';

interface ILogoListProps {
    logosData: Array<LogoDetailResponseData>;
    selected: SelectedState;
    setSelected: (selected: SelectedState) => void;
    logoBaseUrl: string;
    isDefaultAndLocked: boolean;
    allowedLogos: string[];
    accountLogoAllowed: boolean;
}

export const LogoList: React.FC<ILogoListProps> = (
    {
        logosData,
        selected,
        setSelected,
        logoBaseUrl,
        isDefaultAndLocked,
        allowedLogos,
        accountLogoAllowed,
    },
) => {
    const { searchTerm, filteredItems, handleSearchChange } = useSearch(logosData);
    return (
        <div className={style.logoSelectorListContainer}>
            <div className={style.logoList}>
                {(
                    <div className={style.logoSearch}>
                        <input
                            type="text"
                            placeholder="Search by Name or Design Number"
                            value={searchTerm}
                            onChange={e => handleSearchChange(e.target.value)}
                        />
                    </div>
                )}
                {filteredItems.map((logo, index) => {
                    if ((isDefaultAndLocked && logo.logo_number !== selected.logoNumber) ||
                        (!accountLogoAllowed && allowedLogos.length > 0 && !allowedLogos.includes(logo.logo_number))
                    ) {
                        return '';
                    }

                    let currentlySelected = false;
                    const currentlySelectedItem = selected;
                    // If logo is currently being selected,
                    // set the variable to use later, and also reload selected values for preview
                    if (selected && selected.logoNumber === logo.logo_number) {
                        currentlySelected = true;

                        if (!selected.src) {
                            currentlySelectedItem.src = logoBaseUrl + logo.customer_id + logo.logo_path;
                            currentlySelectedItem.title = logo.design_description;
                            setSelected(currentlySelectedItem);
                        }
                    }

                    return (
                        <LogoListItem
                            key={logo.logo_path}
                            itemIndex={index}
                            logo={logo}
                            currentlySelected={currentlySelected}
                            logoBaseUrl={logoBaseUrl}
                            handleClick={() => {
                                setSelected({
                                    logoNumber: logo.logo_number,
                                    title: logo.design_description,
                                    src: logoBaseUrl + logo.customer_id + logo.logo_path,
                                    stitchCount: logo.stitch_count,
                                });
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
};
