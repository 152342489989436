import React, { useState, useEffect } from 'react';
import { LoadingImage } from 'ui/component/loading-image';
import { LogoValueObject } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { LogoResponseData } from 'ui/component/embroidery-configurator/api/embroidery-repository';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { SubsectionTitle } from 'ui/component/embroidery-configurator/components/subsection-title';
import { LogoList } from './logo-list';
import { LogoPreview } from './logo-preview';
import uploadStyle from '../logo-upload/style.css';
import style from './style.css';

interface LogoOnFileSelectorProps {
    valueObj: LogoValueObject;
    handleChange: (v) => void;
    handleRemove: (v) => void;
    handleLoadLogos: () => Promise<null | LogoResponseData>;
    isDefaultAndLocked: boolean;
    allowedLogos: string[];
    setIsLogoSet: React.Dispatch<React.SetStateAction<boolean>>;
    accountLogoAllowed: boolean;
}

export interface SelectedState {
    logoNumber: string;
    title: string | null;
    src: string | null;
    stitchCount: string | null;
}

export const LogoOnFileSelector: React.FC<LogoOnFileSelectorProps> = (
    {
        valueObj,
        handleChange,
        handleRemove,
        handleLoadLogos,
        isDefaultAndLocked,
        allowedLogos,
        setIsLogoSet,
        accountLogoAllowed,
    },
) => {
    const t = usePhraseTranslater();
    const [selected, setSelected] = useState<SelectedState | null>(valueObj ? {
        logoNumber: valueObj.value,
        title: null,
        src: null,
        stitchCount: null,
    } : null);
    const [loading, setLoading] = useState(true);
    const [logosData, setLogosData] = useState([]);
    const [logoBaseUrl, setLogoBaseUrl] = useState(null);

    useEffect(() => {
        handleLoadLogos().then((response) => {
            setLoading(false);
            if (response) {
                setLogoBaseUrl(response.logo_base_url);
                setLogosData(response.logo_detail);
            }
        });
    }, []);

    useEffect(() => {
        // adding back navigation event when logo from file is selected
        const backElement = document.getElementById('emb-page-backbutton');
        const backEventHandle = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setSelected(null);
            handleRemove(e);
        };
        if (selected?.logoNumber && backElement) {
            backElement.addEventListener('click', backEventHandle);
        }
        if (selected?.logoNumber) {
            setIsLogoSet(true);
        } else {
            setIsLogoSet(false);
        }
        // clean up
        return () => backElement && backElement.removeEventListener('click', backEventHandle);
    }, [selected]);

    return (
        <div className={style.logoSelectorWrapper}>
            <div className={style.logoSelectorContainer}>
                <div className={style.logoSelectorBody}>
                    {loading ? (
                        <div className={uploadStyle.loadingImageContainer}>
                            <LoadingImage parentClass={uploadStyle.logoUploadLoading} />
                        </div>
                    ) : (
                        <>
                            {(logosData.length !== 0 && selected && selected.logoNumber) ? (
                                <>
                                    <LogoPreview
                                        selected={selected}
                                        handleChange={handleChange}
                                    />
                                </>
                            ) : (
                                <>
                                    <SubsectionTitle>{t('select logo')}</SubsectionTitle>
                                    {logosData.length === 0 ? (
                                        <div className={style.noLogoData}>
                                            {t('You currently do not have any on-file logos to select.')}
                                        </div>
                                    ) : (
                                        <LogoList
                                            logosData={logosData}
                                            selected={selected}
                                            setSelected={setSelected}
                                            logoBaseUrl={logoBaseUrl}
                                            isDefaultAndLocked={isDefaultAndLocked}
                                            allowedLogos={allowedLogos}
                                            accountLogoAllowed={accountLogoAllowed}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
