import React from 'react';
import { IProductImage } from '@silkpwa/magento/api/util/product';
import { Loading } from './loading';
import { Carousel } from './carousel';
import { ILiquidPixelImage } from '../../liquid-pixel';

export interface IMediaImages {
    type: string;
    images?: IProductImage[] | ILiquidPixelImage[];
}

interface IShowMediaProps {
    product: any;
    images: IMediaImages;
}

export const ShowMedia: React.FC<IShowMediaProps> = ({ product, images }) => {
    // exclude thumbnail images from gallery for bundle product
    const getFilteredImages = (images: any) => {
        try {
            if (product.type !== 'bundle') {
                return images;
            }
            // eslint-disable-next-line camelcase
            return images.filter(item => !item.is_thumbnail);
        } catch (e) {
            return images;
        }
    };

    switch (images.type) {
        case 'loading':
            return <Loading />;
        case 'images':
            return <Carousel images={getFilteredImages(images.images || [])} product={product} />;
        default:
            return null; // TODO: raise HDIGH
    }
};
