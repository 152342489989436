import React, { useEffect, useState } from 'react';
import { connectReviews } from '@silkpwa/module/react-component/connect-reviews';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import screenSwitch from 'ui/styles/screen-switch.css';
import { useHomePageReviews } from '../../../../native-reviews/hooks/useProductReviews';
import { ShowLoader } from '../../../../show-loader';
import { Slider } from './slider';
import styles from './style.css';

const HomePageReviewsWidgetImpl = () => {
    const t = usePhraseTranslater();
    const [currentPage, setCurrentPage] = useState(1);
    const {
        homePageReviews,
        total,
        loading,
        refetch,
    } = useHomePageReviews(currentPage);
    const handlePageChange = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };
    useEffect(() => {
        refetch();
    }, [currentPage, refetch]);
    const reviews = homePageReviews;
    if ((!reviews || total === 0) && !loading) {
        return null;
    }
    return (
        <div className={styles.reviews}>
            <h2 className={styles.reviewsHeader}>{t('Reviews are In')}</h2>
            <div className={screenSwitch.showOnDesktop}>
                <Slider show={4} items={reviews} refetch={handlePageChange} page={currentPage} />
            </div>
            <div className={`${screenSwitch.showOnLargeMobile} ${screenSwitch.showOnMediumMobile}`}>
                <Slider show={2} items={reviews} refetch={handlePageChange} page={currentPage} />
            </div>
            <div className={`${screenSwitch.showOnSmallMobile} ${screenSwitch.showOnVerySmallMobile}`}>
                <Slider show={1} items={reviews} refetch={handlePageChange} page={currentPage} />
            </div>
            {(loading) && (
                <div className={styles.sliderLoader}>
                    <ShowLoader message="LOADING MORE REVIEWS..." />
                </div>
            )}
        </div>
    );
};

export const HomePageReviewsWidget = connectReviews(HomePageReviewsWidgetImpl);
