import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { findLogoRulePerLine } from './find-logo-rule-per-line';

import IPRuleLogoEmb = Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleLogoEmbInterface;

/**
 * LineNumber > 0
 * @param lineNumber
 * @param embroiderer
 */
export const showLogosOnFile = (
    lineNumber: number,
    embroiderer: EmbroidererProps,
): boolean => {
    const foundLogoLine: IPRuleLogoEmb|undefined = findLogoRulePerLine(lineNumber, embroiderer);
    if (!foundLogoLine) {
        return true;
    }

    const {
        logos_on_file: logosOnFile,
        account_logo_allowed: accountLogoAllowed,
    } = foundLogoLine;
    return logosOnFile || accountLogoAllowed;
};
