import { gql, TypedDocumentNode } from '@apollo/client';

export const FETCH_RELATED_PRODUCTS: TypedDocumentNode = gql`
    query RELATED_PRODUCTS_BY_STORE(
        $type: String,
        $productId: Int,
        $storeId: Int,
        $location: LocationEnum
    ) {
        relatedProductsByStore(
            input: {
                type: $type,
                productId: $productId,
                storeId: $storeId,
                location: $location
            }
        ) {
            total_count
            items {
                id
                sku
                name
                url_key
                type_id
            }
        }
    }
`;
