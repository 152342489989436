import React from 'react';
import { ProductCard } from 'ui/component/product/product-card';
import { Images } from './images';
import style from './style.css';

export const CardBody = ({
    product,
    images,
    openQuickView,
    location,
}) => (
    <ProductCard
        theme={style}
        product={product}
        location={location}
        displayed={1}
        image={
            <Images product={product} openQuickView={openQuickView} images={images} location={location} />
        }
    />
);
