import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { findLogoRulePerLine } from './find-logo-rule-per-line';

import IPRuleLogoEmb = Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleLogoEmbInterface;

/**
 * LineNumber > 0
 * @param lineNumber
 * @param embroiderer
 */
export const getAccountLogoAllowedForLine = (lineNumber: number, embroiderer: EmbroidererProps): boolean => {
    const foundLogoLine: IPRuleLogoEmb|undefined = findLogoRulePerLine(lineNumber, embroiderer);
    if (!foundLogoLine) {
        return false;
    }

    const { account_logo_allowed: accountLogoAllowed } = foundLogoLine;
    return accountLogoAllowed;
};
